import React from "react";
import "./home.scss";
import Navbar from "./navbar/Navbar";
import Contacts from "./contacts/contacts";

export default function HOME() {
 
  return (
    <>
      <section id="home">
        <Navbar />
        <Contacts />
      </section>
    </>
  );
}
