import { useState } from "react";
import ERROR from "../../usefulComponents/informativeComponents/ERROR";
import Ellipsis from "../../usefulComponents/loadingComponents/ellipsis";
import useRecover from "../../../hooks/componentHooks/recoverHook";
import { useParams } from "react-router-dom";
import "../../auth/recovery/recover.scss";
import RECOVERY_SUCCESS from "./recovery_Success";
import AnimBackground from "../../usefulComponents/otherComponents/svgBackground";

export default function SetPassword() {
  const SUBMIT_INFO = useRecover();

  const { token } = useParams();
  let [getValue, setValue] = useState({
    new_password: "",
    confirm_password: "",
    error: { inputError: null, otherError: null },
    isLoaded: true,
    success: false,
  });

  function handleChange(e) {
    let { value, name } = e.target;
    setValue((rest) => ({
      ...rest,
      [name]: value,
      error: { inputError: null, otherError: null },
    }));
  }

  async function submitData(e) {
    e.preventDefault();
    setValue((rest) => ({ ...rest, isLoaded: false }));
    let password = {
      new_password: getValue.new_password,
      confirm_password: getValue.confirm_password,
    };

    const response = await SUBMIT_INFO(
      `/user/password/setnewpassword/${token}`,
      password
    );

    response.status !== 200
      ? setValue((rest) => ({
          ...rest,
          error: {
            inputError: response?.inputError,
            otherError:
              response.status !== 400
                ? { message: response?.message, status: response.status }
                : null,
          },
          isLoaded: true,
          success: false,
        }))
      : setValue((rest) => ({
          ...rest,
          new_password: "",
          confirm_password: "",
          success: true,
          error: { inputError: null, otherError: null },
          isLoaded: true,
        }));
  }

  return (
    <div className="recover-container">
      {!getValue.success ? (
        <div className="reset glass">
          <div className="resetPassword">
          <img src="https://d34ct18kvr7jx3.cloudfront.net/assets/logo/logo96.png" alt="logo" />
            <h3>Choose your new Strrings ID password.</h3>
          </div>

          <form onSubmit={submitData}>
            <div>
              <input
                type="password"
                name="new_password"
                value={getValue.new_password}
                onChange={handleChange}
                placeholder="Enter New Password"
                required
              />
              <ERROR error={getValue.error?.inputError?.new_password} />
              <input
                type="password"
                name="confirm_password"
                value={getValue.confirm_password}
                onChange={handleChange}
                placeholder="Re-enter New Password"
                required
              />
              <ERROR error={getValue.error?.inputError?.confirm_password} />
            </div>
            <ERROR error={getValue.error?.otherError} />
            <div id="submitEmail">
              {!getValue.isLoaded ? (
                <Ellipsis />
              ) : (
                <input type="submit" value="Submit" className="myBtn" />
              )}
            </div>
          </form>
        </div>
      ) : (
        <RECOVERY_SUCCESS />
      )}
      <AnimBackground
      color0={"#322DED"}
      color1={"#B537F0"}
      color2={"#F241EF"}
      color3={"#3C37F0"}
      color4={"#F2669B"}
      />
    </div>
  );
}
