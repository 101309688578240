import { useState } from "react";
import "../../../App.scss";
import { Link } from "react-router-dom";
import VerifyUser from "../verification/VerifyUser";
import ERROR from "../../usefulComponents/informativeComponents/ERROR";
import Ellipsis from "../../usefulComponents/loadingComponents/ellipsis";
import AnimBackground from "../../usefulComponents/otherComponents/svgBackground";
import usePostHook from "../../../hooks/httpHooks/usePostHook";

export default function Signup() {
  const POST = usePostHook();
  const [visibility,setVisibility] = useState(false)
  const [item, setItem] = useState({
    email: "",
    password: "",
    confirm_password: "",
    username: "",
    inputError: null,
    otherError: null,
    isLoaded: true,
    verifyNow: false,
  });

  const {
    email,
    password,
    username,
    confirm_password,
    isLoaded,
    inputError,
    otherError,
    verifyNow,
  } = item;

  function changeState(changes) {
    setItem((rest) => ({ ...rest, ...changes }));
    return;
  }

  function handleChange(e) {
    const { value, name } = e.target;
    changeState({ [name]: value, inputError: null, otherError: null });
  }

  async function submitData(e) {
    e.preventDefault();
    changeState({ isLoaded: false, inputError: null, otherError: null });
    const isSignup = await POST(
      {
        username,
        email,
        password,
        confirm_password,
      },
      "/signup",
      false
    );

    if (isSignup.status === 202) {
      changeState({ verifyNow: true, isLoaded: true });
    } else if (isSignup?.status === 400) {
      changeState({
        inputError: isSignup?.inputError,
        isLoaded: true,
      });
    } else {
      changeState({
        otherError: {
          message: isSignup?.message,
          status: isSignup?.status,
        },
        isLoaded: true,
      });
    }
  }

  const handleVisibility = ()=> setVisibility(!visibility)

  let changeVisibility = visibility ? "text" : "password"

  return (
    <div className="auth-container">
      <div id="signup" className="auth-box glass layers-anim">
        <div className="authHeader">
          <div id="appNameAndLogo">
            <img
              id="app_logo"
              src="https://d34ct18kvr7jx3.cloudfront.net/assets/logo/logo96.png"
              alt="logo"
            />
            <h2>Strrings</h2>
          </div>
          <p>Create your Strrings Account</p>
        </div>

        <div className="authInputBox">
          <form className="auth-form" onSubmit={submitData}>
            <div className="textInputs">
              <div className="input_wrapper">
                <label htmlFor="username">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className={inputError?.username ? "input_error" : ""}
                    value={username}
                    onChange={handleChange}
                    placeholder="Username"
                    maxLength="30"
                    required
                  />
                  <span className="spanPlaceholder">Username</span>
                </label>
                <ERROR error={inputError?.username} />
              </div>

              <div className="input_wrapper">
                <label htmlFor="email">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={inputError?.email ? "input_error" : ""}
                    value={email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                  <span className="spanPlaceholder">Email</span>
                </label>
                <ERROR error={inputError?.email} />
              </div>
              <div className="input_wrapper">
                <label htmlFor="password">
                  <input
                    type={changeVisibility}
                    id="password"
                    name="password"
                    className={inputError?.password ? "input_error" : ""}
                    value={password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                  />
                  <span className="spanPlaceholder">Password</span>
                </label>
                <ERROR error={inputError?.password} />
              </div>

              <div className="input_wrapper">
                <label htmlFor="confirm_password">
                  <input
                    type={changeVisibility}
                    id="confirm_password"
                    className={
                      inputError?.confirm_password ? "input_error" : ""
                    }
                    name="confirm_password"
                    value={confirm_password}
                    onChange={handleChange}
                    placeholder="Confirm Password"
                    required
                  />
                  <span className="spanPlaceholder">Confirm Password</span>
                </label>
                <ERROR error={inputError?.confirm_password} />
              </div>
              <div className="pv">
                <input
                  type="checkbox"
                  name="password_visibility"
                  id="password_visibility"
                  onClick={handleVisibility}
                />
                <label htmlFor="password_visibility">Show Password</label>
              </div>
            </div>
            <ERROR error={otherError} />
            <div className="inputsBtn">
              <div id="signin_Btn" className="next">
                {isLoaded ? (
                  <p>
                    Already have an account?{" "}
                    <Link to="/signin" replace>
                      Login
                    </Link>
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="next">
                {!isLoaded ? (
                  <Ellipsis />
                ) : (
                  <input
                    id="signupBtn"
                    className="continueBtn"
                    type="submit"
                    value="Continue"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <VerifyUser
        verificationType={"signupVerification"}
        verifyNow={verifyNow}
        cancelVerification={setItem}
      />
      <AnimBackground
        color0={"#322DED"}
        color1={"#B537F0"}
        color2={"#F241EF"}
        color3={"#3C37F0"}
        color4={"#F2669B"}
      />
    </div>
  );
}
