import { useState } from "react";
import "./search.scss";
import useUI from "../../../../hooks/usefulHooks/useUI";
import useGetHook from "../../../../hooks/httpHooks/useGetHook";
import useDebounceHook from "../../../../hooks/utilityHooks/debounceHook";

export default function SearchBar({ handleSearchMode }) {
  const {
    setInterface,
    Interface: { searchResult },
  } = useUI();
  const [input, setInput] = useState("");
  const GET = useGetHook();
  useDebounceHook(search, input);

  function searchUser(e) {
    let text = e.target.value;
    setInterface({
      searchResult: { error: null, ...searchResult },
    });
    setInput(text);
  }

  async function search() {
    let data;
    let isSearched = false;

    if (!input.length) {
      data = await GET(`/api/get-all-users`, true);
      setInterface({ isLoaded: true });
      isSearched = false;
    } else {
      data = await GET(`/api/search/user/account?username=${input}`, true);
      setInterface({ isLoaded: true });
      isSearched = true;
    }

    let { users, message, status } = data;

    if (status === 200) {
      let setUsers = users ? users : [];
      setInterface({
        searchResult: { isSearched: isSearched, users: setUsers },
      });
    } else {
      setInterface({
        searchResult: {
          isLoaded: true,
          error: { message, status },
          isSearched: isSearched,
          users: [],
        },
      });
    }
  }

  return (
    <div className="search-box">
      <button onClick={handleSearchMode} className="disable-search background">
        <i className="fas fa-arrow-left"></i>
      </button>
      <input
        onChange={searchUser}
        type="search"
        className="input-search"
        placeholder="Type to search..."
      />
    </div>
  );
}
