import { useEffect } from "react";
import useTimer from "../../../hooks/utilityHooks/timerHook";

export default function Countdown({ duration, cb }) {
  const { days, hours, minutes, seconds } = useTimer(+duration.time);
  const remainingTime = days + hours + minutes + seconds;
  let isRunning = +remainingTime > 0;

  useEffect(() => {
    let isMounted = true;

    if (isMounted && (!duration.waiting || !isRunning)) {
      cb(); /// this callback function is used to reset all related task after timer stoped
    }
    return () => {
      isMounted = false;
    };
  }, [duration, isRunning]);

  return (
    <>
      {isRunning ? (
        <div id="timer">
          {+days > 0 ? (
            <span>{`Resend verification code in ${1 + +days} days`}</span>
          ) : (
            <span>{`Resend verification code in ${
              hours > 0 ? hours + " : " : ""
            }${minutes} : ${seconds}`}</span>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
