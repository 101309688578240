import useGetHook from "../httpHooks/useGetHook";
import usePostHook from "../httpHooks/usePostHook";
import useAuth from "../usefulHooks/useAuth";
import useUI from "../usefulHooks/useUI";

export default function useVerificationHook() {
  const GET = useGetHook();
  const POST = usePostHook();
  const { setInterface } = useUI();
  const { auth, setAuth } = useAuth();

  async function SUBMIT_CODE(info) {
    const response = await POST(info, "/api/user/email/verification/done", false);
    if (response.status === 200) {
      setAuth((rest) => ({
        ...rest,
        user: { ...auth.user, ...response.updated },
      }));
      setInterface({
        verification: { isVerified: response.success, message: "" },
      });
    }
    return response;
  }

  async function RESEND_CODE() {
    const { message, success, time, status } = await GET(
      "/api/user/email/verificaiton/resend",
      false
    );
    return { message, success, status, time };
  }

  return { SUBMIT_CODE, RESEND_CODE };
}
