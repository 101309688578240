const useFieldChecker = () => {
  const preValidator = (data) => {
    const requiredFields = {
      first_name: "First Name",
      username: "Username",
      password: "Password",
      new_password: "New password",
      current_password: "Current password",
      confirm_password: "Confirm password",
      email: "Email",
    };
    const fields = data;
    const names = Object.keys(requiredFields);
    const inputError = {};
    for (let key in fields) {
      if (names.includes(key) && fields[key] === "") {
        inputError[key] = [
          { message: `Enter a ${requiredFields[key]}`, name: key },
        ];
      }
    }
    let result = Object.keys(inputError).length ? inputError : null;
    return result;
  };

  return preValidator;
};

export default useFieldChecker;
