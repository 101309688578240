import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { ChatProvider } from "./context/chatContext";
import { UIProvider } from "./context/UIContext";
import { SocketProvider } from "./context/socketContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <AuthProvider>
          <SocketProvider>
            <ChatProvider>
              <UIProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </UIProvider>
            </ChatProvider>
          </SocketProvider>
        </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
