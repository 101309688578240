import React from "react";

export default function About() {
  return (
    <div id="app_About">
      <div id="app_version">
        <img
          src="https://d34ct18kvr7jx3.cloudfront.net/assets/logo/logo96.png"
          alt="logo"
        />

        <strong>Strrings</strong>
        <span>Version 1.0.1</span>
      </div>
      <div id="Prince_info">
        <p>Developed and designed by Prince with ❤</p>
      </div>
    </div>
  );
}
