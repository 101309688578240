import React from "react";
import { useNavigate } from "react-router-dom";
import useUI from "../../../hooks/usefulHooks/useUI";

export default function Header({ name, link, backClick }) {
  const navigate = useNavigate();
  const {
    Interface: {
      appearance: { components_background },
    },
  } = useUI();

  function goBack() {
    return link ? navigate(-1) : backClick();
  }

  return (
    <div className={`${components_background} Header`}>
      <button className="backButton back background" onClick={goBack}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
      </button>
      <h3>{name}</h3>
    </div>
  );
}
