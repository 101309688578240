import { useEffect, useState } from "react";
import "./profile.scss";
import useUI from "../../../../hooks/usefulHooks/useUI";
import useGetHookEffect from "../../../../hooks/httpHooks/useGetHookEffect";

export default function Profile() {
  const { Interface, setInterface } = useUI();

  const { profile } = Interface;
  const [avatar, setAvatar] = useState(profile?.picture);
  const { response } = useGetHookEffect(
    `/api/get/contact/avatar?user-avatar=${profile?.picture}`
  );

  useEffect(() => {
    let isMounted = true;
    if (response) {
      if (isMounted && response.status === 200) {
        setAvatar(response.avatar);
      } else {
        setAvatar(profile?.picture);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [response]);

  let contactInfo = [
    { "Status:": profile?.status },
    {
      "Joined at:": new Date(profile?.joined_at).toDateString(),
    },
    {
      "Last seen:": new Date(profile?.last_seen).toDateString(),
    },
    {
      About: profile?.about,
    },
  ];

  return (
    <div id="userProfileLayout" className="smooth-anim">
      <div
        style={{ backgroundImage: `url(${profile?.picture})` }}
        id="forBlurBackground"
      >
        <div className="contactInfo">
          <button
            title="Close"
            className={`arrow_btn hideProfileBtn`}
            onClick={() =>
              setInterface({
                show_Profile: false,
                home_view: "Chats",
              })
            }
          >
            <i className="fas fa-times" aria-hidden="true"></i>
          </button>

          <a
            href={avatar}
            target="_blank"
            rel="noreferrer"
            className="userAvatar"
          >
            <img src={avatar} alt="userAvatar" />
          </a>
          <div className="contactUsername">
            <h1>{profile?.username}</h1>
          </div>

          <div id="allInfo">
            {contactInfo.map((e, i) => (
              <div key={i} className="small-info-container">
                <div className="small-info-header">
                  <span>{Object.keys(e)}</span>
                </div>

                <div className="small-info">
                  <p>{Object.values(e)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
