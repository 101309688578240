import axios from "../../api/axios";
import handleResponse from "../../modules/responseHandler";

export default function useRecover() {

  async function SUBMIT_INFO(url,info) {
    let infoJson = JSON.stringify(info);
    
    try {
      let response = await axios.post(url, infoJson);
      let data = handleResponse(response,true)
      if (data.status === 200) {
        return data;
      }
    } catch (error) {
      return handleResponse(error,false);
    }
  }
  return SUBMIT_INFO;
}
