import { createContext, useState, useEffect } from "react";
import useAuth from "../hooks/usefulHooks/useAuth";

const UIContext = createContext({});

export function UIProvider({ children }) {
  let { auth } = useAuth();
  let [Interface, setInterface] = useState({
    show_Options: false,
    show_Profile: false,
    home_view: "Chats",
    profile: null,
    mobile_view: false,
    backToSettings: false,
    isVerified: true,
    isLoaded: true,
    alert: null,
    appearance: {
      dark_mode: false,
    },
    searchMode: false,
    searchResult: { error: null, isSearched: false, users: [] },
  });

  function changeState(changes) {
    setInterface((unChanged) => ({ ...unChanged, ...changes }));
    return;
  }

  const Auto = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 18 || currentHour < 6) {
      Theme(true); // enable dark mode
    } else {
      Theme(false); // Disable dark mode
    }
    return;
  };

  useEffect(() => {
    let isUpdated = false;

    if (!isUpdated) {
      const system_default = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      const defaultMode = auth?.user?.appearance?.dark_mode || "system_default";

      if (defaultMode === "auto") {
        Auto();
      } else if (defaultMode === "system_default") {
        Theme(system_default);
      } else if (defaultMode === "on") {
        Theme(true);
      } else {
        Theme(false);
      }
    }

    return () => {
      isUpdated = true;
    };
  }, [auth]);

  function Theme(command) {
    changeState({
      appearance: {
        dark_mode: command,
      },
    });
    return;
  }

  return (
    <UIContext.Provider value={{ Interface, setInterface: changeState }}>
      {children}
    </UIContext.Provider>
  );
}

export default UIContext;
