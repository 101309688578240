import { useNavigate } from "react-router-dom";
import useOnline from "../../../../../hooks/utilityHooks/useOnline";
import useUnreadAndLast from "../../../../../hooks/utilityHooks/unread_last";
import useUI from "../../../../../hooks/usefulHooks/useUI";
import MessageStatus from "../../../../usefulComponents/chatComponents/messageStatus";
import useChat from "../../../../../hooks/usefulHooks/useChat";
import NotAvailable from "../../../../usefulComponents/ReuseableComponents/notAvailable";
import shortName from "../../../../../modules/shortName";

export default function ContactList({ contacts, error }) {
  const navigate = useNavigate();
  const { chatOption, setChatOption } = useChat();
  const { setInterface } = useUI();
  const UNREAD_LAST = useUnreadAndLast();
  const { isOnline, isTyping } = useOnline();

  function showProfile(e,user) {
    e.stopPropagation();
    setInterface({
      show_Profile: true,
      home_view: "Profile",
      profile: user,
    });
  }

  const openChat = (user) => {
    setChatOption((rest) => ({ ...rest, currentUser: user }));
    navigate(`/chats/${user.userID}`);
    return;
  };

  let recentMsg = chatOption.isRecentMessagesFetched;
  return error ? (
    <NotAvailable message={error[1]} convey={error[0]} />
  ) : (
    <ul className="contents-container">
      {contacts.map((user) => {
        return (
          <li
            className="contactsList"
            onClick={() => openChat(user)}
            key={user.username}
          >


            <div className="avatarList">
              <input
                onClick={(e) => showProfile(e,user)}
                type="image"
                className="contactsAvatar"
                src={user.picture}
                alt="profile_img"
              />
            </div>

            <div className="nameList">
              <div className="name_and_count">
                <p className="contact_name" >{shortName(user.username, 20)}</p>
                <span
                  className={
                    UNREAD_LAST(user.userID).getUnreadMessages
                      ? `unread_msg_count`
                      : `invisible`
                  }
                >
                  {UNREAD_LAST(user.userID).getUnreadMessages}
                </span>
              </div>

              <div className="contact_last_info">
                <div>
                  <span
                    className={isOnline(user) ? "online_status" : ""}
                  ></span>

                  {!recentMsg ? (
                    "Getting recent messages..."
                  ) : isTyping(user) ? (
                    <span className="contact_msg_info">typing...</span>
                  ) : (
                    <span className="contact_msg_info">
                      {UNREAD_LAST(user.userID).getLastMessage}
                      <MessageStatus
                        e={chatOption.lastMessage?.[user.userID]}
                      />
                    </span>
                  )}
                </div>

                <div>
                  <span className="last_msg_time">
                    {UNREAD_LAST(user.userID).time}
                  </span>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
