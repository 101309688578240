import React from "react";
import "./verifyUser.scss";
import useAuth from "../../../hooks/usefulHooks/useAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePostHook from "../../../hooks/httpHooks/usePostHook";
import useGetHook from "../../../hooks/httpHooks/useGetHook";
import SixInput from "../../usefulComponents/otherComponents/sixInput";
import Error from "../../usefulComponents/informativeComponents/ERROR";
import Ellipsis from "../../usefulComponents/loadingComponents/ellipsis";
import Countdown from "../../usefulComponents/otherComponents/countdown";
import useDeleteHook from "../../../hooks/httpHooks/useDeleteHook";

export default function VerifyUser({
  verificationType,
  verifyNow,
  cancelVerification,
}) {
  const { setAuth } = useAuth();
  const POST = usePostHook();
  const GET = useGetHook();
  const DELETE = useDeleteHook();
  let [item, setItem] = useState({
    input: "",
    clearInput: false,
    error: null,
    isLoaded: { isSubmitted: true, isResent: true },
    suspend: { waiting: false, time: 0 },
    isDisabled: { submit: false, resend: false },
  });

  function changeState(changes) {
    setItem((rest) => ({ ...rest, ...changes }));
    return;
  }

  let navigate = useNavigate();
  let from = "/";
  let doThisVerification =
    verificationType === "signupVerification"
      ? [
          "/verify-user-account",
          "/resend-account-verification-code",
          "signupVerification",
          "Verify your email address",
        ]
      : [
          "/two-step-verification",
          "/resend-2-step-verification-code",
          "two-step-verification",
          "Two-Step-Verification",
        ];

  function validUser(data) {
    let { user, accessToken } = data;
    setAuth({ user, accessToken });
    navigate(from, { replace: true });
  }

  async function submitData(e) {
    e.preventDefault();
    changeState({
      error: null,
      isDisabled: { submit: true, resend: true },
      isLoaded: { isSubmitted: false, isResent: true },
    });
    let response = await POST(
      { code: item.input },
      doThisVerification[0]
    );

    if (response.status !== 200) {
      changeState({
        error: response?.inputError?.code || {
          message: response?.message,
          status: response?.status,
        },
        isDisabled: {
          submit: false,
          resend: !item.isDisabled.resend ? false : true,
        },
        isLoaded: { isSubmitted: true, isResent: true },
      });
    } else {
      changeState({
        isDisabled: { submit: false, resend: false },
        isLoaded: { isSubmitted: true, isResent: true },
        clearInput: true,
        suspend: { waiting: false, time: 0 },
      });
      return validUser(response.user);
    }
  }

  async function resend(e) {
    e.preventDefault();
    changeState({
      error: null,
      isDisabled: { submit: true, resend: true },
      isLoaded: { isSubmitted: true, isResent: false },
    });

    let { message, success, time, status } = await GET(doThisVerification[1]);

    if (success) {
      changeState({
        suspend: { waiting: true, time },
        isDisabled: { submit: false, resend: true },
      });
    } else if (!success && status !== 202) {
      changeState({
        isLoaded: { isSubmitted: true, isResent: true },
        isDisabled: { submit: false, resend: false },
        error: { message },
        suspend: { waiting: false, time: 0 },
      });
    }
  }

  async function cancel(e) {
    e.preventDefault();
    if (doThisVerification[2] === "signupVerification") {
      await DELETE("cancel-verification");
    }

    changeState({
      suspend: { waiting: false, time: 0 },
      isLoaded: { isSubmitted: true, isResent: true },
      isDisabled: { submit: false, resend: false },
      error: null,
      clearInput: true,
      input: "",
    });
    cancelVerification((rest) => ({
      ...rest,
      verifyNow: false,
    }));
    return;
  }

  function get_Otp_Value(value) {
    changeState({
      input: value,
      error: null,
    });
  }

  function reset() {
    changeState({
      isDisabled: { submit: false, resend: false },
      isLoaded: { isSubmitted: true, isResent: true },
      suspend: { waiting: false, time: 0 },
    });
  }

  let verificaion = verifyNow ? "verification_container_startup" : "hide";

  return (
    <div className={verificaion}>
      <div id="verification-Box" className="glass">
        <h2 className="verify_email">{doThisVerification[3]}.</h2>
        <div id="verify-illustration">
        <i className="fa-solid fa-envelope-circle-check"></i>
        </div>
        <div id="hint">
          <p>We've sent a verificaiton code to your provided email address.</p>
        </div>

        <form className="verificaion-input-box" onSubmit={submitData}>
          <SixInput
            get_otp={get_Otp_Value}
            //  handleSubmit={submitData}
            clearInput={item.clearInput}
            setItem={setItem}
          />
          <Error error={item.error} align={"center"} />

          {!item.isLoaded.isSubmitted ? (
            <Ellipsis />
          ) : (
            <div className="form_btns">
              <input
                disabled={item.isDisabled.submit}
                className={`input-buttons ${
                  item.isDisabled.submit ? `disabled_btn` : ""
                }`}
                id="submit"
                type="submit"
                value="Next"
              />

              {item.suspend.waiting ? (
                <Countdown
                  duration={item.suspend}
                  forceStop={setItem}
                  cb={reset}
                />
              ) : !item.isLoaded.isResent ? (
                <Ellipsis />
              ) : (
                <input
                  disabled={item.isDisabled.resend}
                  id="resend"
                  type="button"
                  onClick={resend}
                  value="Send a new code"
                  className={`input-buttons ${
                    item.isDisabled.resend ? `disabled_btn` : ""
                  }`}
                />
              )}
              <input
                id="cancel"
                type="button"
                value="Cancel"
                onClick={cancel}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
