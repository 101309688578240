import { Link } from "react-router-dom";
import "../../auth/recovery/recover.scss";
import AnimBackground from "../../usefulComponents/otherComponents/svgBackground";

export default function RECOVERY_SUCCESS() {
  return (
    <div className="recover-container">
      <div className="reset glass">
        <div className="resetPassword">
          <h2>Password changed Successfully.</h2>
        </div>
        <Link className="myBtn" to="/signin" replace>
          Signin
        </Link>
      </div>
      <AnimBackground
          color0={"#322DED"}
          color1={"#B537F0"}
          color2={"#F241EF"}
          color3={"#3C37F0"}
          color4={"#F2669B"}
      />
    </div>
  );
}
