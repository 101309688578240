import { useState } from "react";
import useDeleteHook from "../../../../hooks/httpHooks/useDeleteHook";
import usePostHook from "../../../../hooks/httpHooks/usePostHook";
import useAuth from "../../../../hooks/usefulHooks/useAuth";
import useUI from "../../../../hooks/usefulHooks/useUI";
import ERROR from "../../../usefulComponents/informativeComponents/ERROR";
import Ellipsis from "../../../usefulComponents/loadingComponents/ellipsis";
import { useNavigate } from "react-router-dom";

export default function DangerZone() {
  const navigate = useNavigate();
  const POST = usePostHook();
  const { setAuth } = useAuth();
  const { setInterface } = useUI();
  const DELETE = useDeleteHook();

  const [item, setItem] = useState({
    error: null,
    isLoaded: true,
    isAccess: false,
  });
  const [securityCode, setSecurityCode] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    setItem((rest) => ({ ...rest, error: null, isLoaded: false }));
    const { message, status } = await POST(
      { securityCode },
      "/api/user/account/security"
    );
    if (status === 200) {
      setItem((rest) => ({ ...rest, error: null, isAccess: true }));
    } else {
      setItem((rest) => ({ ...rest, error: { message, status } }));
    }
    return setItem((rest) => ({ ...rest, isLoaded: true }));
  }

  async function deleteAccount(e) {
    e.preventDefault();
    setItem((rest) => ({ ...rest, isLoaded: false }));
    const { message, status } = await DELETE("/api/delete/user-account");
    if (status === 200) {
      setItem((rest) => ({ ...rest, isLoaded: true }));
      setInterface({
        alert: {
          success: true,
          message,
        },
      });
      setAuth(null);
      return navigate("/signup", { replace: true });
    } else {
      setItem((rest) => ({
        ...rest,
        isLoaded: true,
      }));
      setInterface({
        alert: {
          success: false,
          message,
        },
      });
      return;
    }
  }

  function handleChange(e) {
    setItem((rest) => ({ ...rest, error: null }));
    return setSecurityCode(e.target.value);
  }
  return (
    <form
      onSubmit={item.isAccess ? deleteAccount : handleSubmit}
      id="delete_ac"
      className="user_info"
    >
      <div className="cells_header">
        <h3>Danger zone</h3>
        <i className="fas fa-trash-alt"></i>
      </div>

      <div id="delete_action">
        {item.isAccess ? (
          <div id="delete-notice">
            <h2>Note: Deleting Your Account</h2>
            <p>
              We understand that you may want to delete your account. Please be
              aware that deleting your account will permanently remove all your
              chat history, contacts, and any other data associated with your
              account.
              <br />
              Before you proceed, please take a moment to consider your
              decision. If you have any concerns or questions about your
              account, feel free to reach out to me at{" "}
              <strong>strrings.world@gmail.com</strong>. I'm here to assist you.
              <br />
              Please note that this action cannot be undone. If you are certain
              about deleting your account, I appreciate the time you spent with
              my app, and I wish you the best in your future endeavors.
            </p>
            <br />
            <strong>
              Thank you for using Strrings. Your support is greatly appreciated
              ❤.
            </strong>
            <br />
            <u>Best regards😍,</u>
            <br />
            <strong>PRINCE</strong>
            <br />
            <u>Developer of Strrings</u>
          </div>
        ) : (
          <div id="access-div" className="input_wrapper">
            <label htmlFor="confirm_access">
              <input
                id="confirm_access"
                type="password"
                name="securityCode"
                placeholder="Enter your password"
                value={securityCode}
                onChange={handleChange}
                required
              />
              <span className="spanPlaceholder">Confirm access</span>
            </label>
            <ERROR error={item?.error} />
            <p>Enter your password, If you want to delete your account.</p>
          </div>
        )}
      </div>

      <div className="profile_save_button">
        {!item.isLoaded ? (
          <Ellipsis />
        ) : item.isAccess ? (
          <input
            type="submit"
            id="delete_btn"
            value="Delete account"
            className="myBtn"
          />
        ) : (
          <input type="submit" value="Confirm" className="myBtn" />
        )}
      </div>
    </form>
  );
}
