import useChat from "../usefulHooks/useChat";
import timeString from "../../modules/lastMsgTime";
import shortName from "../../modules/shortName";

export default function useUnreadAndLast() {
  const { chatOption } = useChat();

  const getUnreadAndLast = (userID) => {
    const { lastMessage, unreadMessage } = chatOption;

    let isLastMsg = lastMessage?.[userID];
    let sentBy = isLastMsg && isLastMsg?.sender !== userID ? "You : " : "";
    let getLastMessage = isLastMsg?.message
      ? `${sentBy}${shortName(isLastMsg?.message?.content,8)}`
      : "";
    let isUnread = unreadMessage?.[userID];
    let getUnreadMessages = isUnread ? isUnread.length : "";
    let time = isLastMsg?.message
      ? timeString(isLastMsg?.message?.timestamp)
      : "";

    return { getUnreadMessages, getLastMessage, time };
  };

  return getUnreadAndLast;
}
