import React from "react"; 
import useAuth from "../../../hooks/usefulHooks/useAuth";

export default function MessageStatus({ e }) {
  const {
    auth: {
      user: { userID },
    },
  } = useAuth();

  if (e?.sender !== userID) {
    return ""; // It only shows the status to sender otherwise it shows nothing
  }

  if (e?.message?.sent && e?.message?.delivered && e?.message?.read) {
    return <i className="far fa-eye delivered_seen"></i>;
  } else if (e?.message?.sent && e?.message?.delivered) {
    return <i className="fas fa-check-double delivered_seen"></i>;
  } else if (e?.message?.sent) {
    return <i className="fas fa-check delivered_seen"></i>;
  } else {
    return <i className="far fa-clock delivered_seen"></i>;
  }
}
