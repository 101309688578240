import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useRefresh from "../../../hooks/usefulHooks/refresh";
import useAuth from "../../../hooks/usefulHooks/useAuth";
import Spinner from "../../usefulComponents/loadingComponents/spinner";

const PersistLogin = () => {
  const [isAppLoading, setIsAppLoading] = useState(true);
  const refresh = useRefresh();
  const { auth } = useAuth();

  useEffect(() => {
    let delay;
    const verifyToken = async () => {
      try {
        await refresh();
      } catch (error) {
        console.error(error);
      } finally {
        delay = setTimeout(() => setIsAppLoading(false), 1000); // To show the app logo for 1.5 sec
      }
    };

    !auth?.accessToken ? verifyToken() : setIsAppLoading(false);
    return () => {
      clearTimeout(delay);
    };
  }, []);

  return (
    <>
      {isAppLoading ? (
        <div id="AppLoading">
          <img
            src="https://d34ct18kvr7jx3.cloudfront.net/assets/logo/logo192.png"
            alt="App_logo"
          />
          <h2>Strrings</h2>
          <Spinner />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
