import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useUI from "../../../hooks/usefulHooks/useUI";
import Alert from "../../usefulComponents/informativeComponents/Alert";
import Ellipsis from "../../usefulComponents/loadingComponents/ellipsis";
import OfflineSvg from "../../usefulComponents/ReuseableComponents/offline_svg";

export default function Layout() {
  const [isOnline, setIsOnline] = useState(true);
  const { Interface, setInterface } = useUI();
  let dark_mode = Interface.appearance.dark_mode ? "dark" : "light";

  // This effect is used for resize the whole app programmetically

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 950) {
        setInterface({
          mobile_view: true,
          collapse: false,
        });
      } else {
        setInterface({
          mobile_view: false,
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <main className={`${dark_mode} app-layout smooth-anim`}>
      <Outlet />
      <div id="alert_container">
        {Interface.isLoaded ? <Alert /> : <Ellipsis />}
      </div>
      {!isOnline ? (
        <div id="offline">
          <div>
            <OfflineSvg />
            <br />
            <span className="errorInfo">
              You're offline. Check your connection.
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </main>
  );
}
