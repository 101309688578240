import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../../hooks/usefulHooks/useAuth";
import io from "socket.io-client";
import useChat from "../../../hooks/usefulHooks/useChat";
import useSocket from "../../../hooks/socketHooks/useSocket";
import useSocketEventHandlers from "../../../hooks/socketHooks/socketOnEvents";

export default function RequireAuth() {
  const { auth } = useAuth();
  const location = useLocation();
  const { setChatOption } = useChat();
  const { socket, setSocket } = useSocket();
  useSocketEventHandlers(socket);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && auth?.user) {
      connectToSocket(); /// Connect to socket if user authenticated
    }

    return () => {
      isMounted = false;
      if (!auth?.user && socket) {
        socket.disconnect();
      }
    };
  }, []);

  function connectToSocket() {
    const newSocket = io("https://www.strrings.com", {
      query: {
        token: auth?.socketToken, // For socket verification
      },
    });
    setSocket(newSocket);

    newSocket.emit("user-online");

    newSocket.on("online-users", (onlineUsers) => {
      setChatOption((rest) => ({
        ...rest,
        onlineUsers,
      }));
    });

    newSocket.on("refresh-online-user-list", (onlineUsers) => {
      setChatOption((rest) => ({
        ...rest,
        onlineUsers,
      }));
    });

    newSocket.on("user-joined", (name) => {
      console.log(`${name} is Online 🟢`);
    });

    newSocket.on("user-left", (name) => {
      console.log(`${name} is Offline 🔴`);
    });
  }

  return auth?.user ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
}
