import React from "react";
import "./chat.scss";
import SideChats from "./elements/sideChats";
import CurrentChat from "./elements/currentChat"; 

export default function Chats() {

  return (
    <div className={`chats-container`}>
      <SideChats />
      <CurrentChat />
    </div>
  );
}
