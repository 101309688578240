import handleResponse from "../../modules/responseHandler";
import useAxiosPrivate from "../usefulHooks/useAxiosPrivate";
import useUI from "../usefulHooks/useUI";

export default function useGetHook() {
  const axiosPrivate = useAxiosPrivate();
  const { setInterface } = useUI();

  async function GET(url, showProgress = false) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const timeoutId = setTimeout(() => {
      abortController.abort();
    }, 30000);

    setInterface({ isLoaded: !showProgress });
    
    try {
      const data = await axiosPrivate.get(url, { signal });
      return handleResponse(data, true);
    } catch (err) {
      const error = handleResponse(err, false);
      if (error.message === "canceled") {
        error.message = "Request canceled due to delay. Please retry.";
      }

      return error;
    } finally {
      clearTimeout(timeoutId); // Clear the timeout if the request succeeds
      abortController.abort(); // Abort the request
    }
  }

  return GET;
}
