import React, { useState } from "react";
import useAuth from "../../../../hooks/usefulHooks/useAuth";
import useUpdate from "../../../../hooks/utilityHooks/useUpdate";
import useUI from "../../../../hooks/usefulHooks/useUI";
import ERROR from "../../../usefulComponents/informativeComponents/ERROR";
import useFieldChecker from "../../../../hooks/utilityHooks/fieldChecker";
import { useLocation, useNavigate } from "react-router-dom";
import usePutHook from "../../../../hooks/httpHooks/usePutHook";
import usePatchHook from "../../../../hooks/httpHooks/usePatchHook";

export default function Security() {
  const PATCH = usePatchHook();
  const UPDATED_FIELDS = useUpdate();
  const CHECK_FIELD = useFieldChecker();
  const PUT = usePutHook();

  const {
    setInterface,
    Interface: { isLoaded },
  } = useUI();

  const {
    setAuth,
    auth: { user },
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [security, setSecurity] = useState({
    two_step_verification: user.security.two_step_verification,
    login_notification: user.security.login_notification,
  });

  const [item, setItem] = useState({
    showPassword: false,
    isCollapsed: true,
    expand: "none",
    inputError: null,
  });

  let { two_step_verification, login_notification } = security;

  function password_visibility() {
    setItem((rest) => ({ ...rest, showPassword: !item.showPassword }));
  }

  function handleChange(e) {
     setItem((rest) => ({ ...rest, inputError: !item.showPassword }));
    const { name, value } = e.target;
    let field = { [name]: value };
    setInput((rest) => ({ ...rest, ...field }));
    CHECK_FIELD(UPDATED_FIELDS(field, user));
  }

  function trackChanges(changes) {
    const changed = {};
    for (let key in changes) {
      if (changes[key] !== "") {
        changed[key] = changes[key];
      }
    }
    return changed;
  }

  async function handleToggle(e) {
    let id = e.target.id;
    let changes = { ...security, [id]: !security[id] };

    let { status, success, message, user } = await PUT(
      changes,
      "/api/user/account/manage/security",
      "application/json;charset=UTF-8",
      true
    );

    if (status === 200) {
      setInterface({ alert: { success, message }, isLoaded: true });
      setAuth((rest) => ({ ...rest, user }));
      setSecurity((rest) => ({ ...rest, [id]: !security[id] }));
    } else {
      setInterface({
        alert: { success: false, message },
        isLoaded: true,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const response = await PATCH(
      input,
      "/api/user/account/manage/password",
      "application/json;charset=UTF-8",
      true
    );
    if (response.status === 200) {
      const { success, message } = response;
      setInput((rest) => ({
        ...rest,
        current_password: "",
        new_password: "",
        confirm_password: "",
      }));
      setInterface({
        isLoaded: true,
        alert: { success, message },
      });
      setItem((rest) => ({ ...rest, inputError: null }));
    } else if (
      response.status === 400 &&
      response.hasOwnProperty("inputError")
    ) {
      setItem((rest) => ({ ...rest, inputError: response?.inputError }));
      setInterface({ isLoaded: true });
    } else {
      setInterface({
        alert: { success: false, message: response?.message },
        isLoaded: true,
      });
    }
    return;
  }

  function expendSection(e) {
    let section = e.target.id || "none";
    navigate(`/settings/#security/${section}`, { replace: true });

    setItem((rest) => ({ ...rest, expand: section }));
    setInterface({
      error: null,
      backToSettings: true,
    });
    setInput((rest) => ({
      ...rest,
      current_password: "",
      new_password: "",
      confirm_password: "",
    }));
  }

  const showPassword = item.showPassword ? ["ON", "text"] : ["OFF", "password"];
  let section = location.hash.match(/\w+$/)?.[0];

  return (
    <>
      {section === "security" ? (
        <div id="Security">
          <button
            id="security_password"
            onClick={expendSection}
            className="change_my_password background"
          >
            <span>Change your password</span>
            <i className="fas fa-angle-right"></i>
          </button>

          <button
            id="security_options"
            onClick={expendSection}
            className="update_security background"
          >
            <span>
              Two-step verification
              <br />
              Security notification
            </span>

            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      ) : (
        ""
      )}

      {section === "security_password" ? (
        <form
          onSubmit={handleSubmit}
          id="submit_password"
          className="security_form"
        >
          <div className="security_input_div">
            <div className="input_wrapper">
              <label htmlFor="current_password">
                <input
                  type={showPassword[1]}
                  id="current_password"
                  name="current_password"
                  value={input.current_password}
                  onChange={handleChange}
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
                <span className="spanPlaceholder">Current Password</span>
              </label>
              <ERROR error={item.inputError?.current_password} />
            </div>
            <div className="input_wrapper">
              <label htmlFor="new_password">
                <input
                  id="new_password"
                  type={showPassword[1]}
                  name="new_password"
                  aria-label="Username"
                  value={input.new_password}
                  onChange={handleChange}
                  aria-describedby="addon-wrapping"
                />
                <span className="spanPlaceholder">New Password</span>
              </label>
              <ERROR error={item.inputError?.new_password} />
            </div>

            <div className="input_wrapper">
              <label htmlFor="confirm_password">
                <input
                  id="confirm_password"
                  type={showPassword[1]}
                  name="confirm_password"
                  aria-label="Username"
                  value={input.confirm_password}
                  onChange={handleChange}
                  aria-describedby="addon-wrapping"
                />
                <span className="spanPlaceholder">Confirm Password</span>
              </label>
              <ERROR error={item.inputError?.confirm_password} />
            </div>
            <div id="show_password">
              <button
                type="button"
                onClick={password_visibility}
                className="fr-center-between background"
                id="show_pwd_btn"
              >
                <span>Password visibility</span> : <span>{showPassword[0]}</span>
              </button>
            </div>
          </div>

          {Object.keys(trackChanges(input)).length !== 0 && isLoaded ? (
            <input type="submit" value="Set New Password" className="myBtn" />
          ) : (
            ""
          )}
        </form>
      ) : (
        ""
      )}

      {section === "security_options" ? (
        <form
          onSubmit={handleSubmit}
          className="security_form fc-center-between"
          id="submit_security_options"
        >
          <div className="settings_options">
            <ul>
              <li className="wide-list-btn feature_available">
                <label
                  className="feature_available"
                  htmlFor="two_step_verification"
                >
                  Two step verification
                </label>
                <input
                  id="two_step_verification"
                  className="toggle_switch"
                  checked={two_step_verification}
                  onChange={handleToggle}
                  type="checkbox"
                />
              </li>

              <li className="wide-list-btn feature_available">
                <label
                  className="feature_available"
                  htmlFor="login_notification"
                >
                  Login notification
                </label>
                <input
                  id="login_notification"
                  className="toggle_switch"
                  checked={login_notification}
                  onChange={handleToggle}
                  type="checkbox"
                />
              </li>
            </ul>
          </div>
        </form>
      ) : (
        ""
      )}
    </>
  );
}
