export default function timeString(getTime) {
  const currentTime = new Date();
  const lastSeenTime = new Date(getTime);

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - lastSeenTime;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.round(hoursDifference / 24);
 

  if (daysDifference === 0) {
    // Last seen today
    const formattedTime = lastSeenTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    return `Today at ${formattedTime}`;
  } else if (daysDifference === 1) {
    // Last seen yesterday
    const formattedTime = lastSeenTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    return `yesterday at ${formattedTime}`;
  } else {
    // Last seen on a previous date
    const formattedDate = lastSeenTime.toLocaleDateString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
    return formattedDate;
  }
}
