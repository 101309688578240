import handleResponse from "../../modules/responseHandler";
import useAxiosPrivate from "../usefulHooks/useAxiosPrivate";

export default function useDeleteHook() {
  const axiosPrivate = useAxiosPrivate();
  async function DELETE(url) {
    try {
      const getData = await axiosPrivate.delete(url);
      return handleResponse(getData, true);
    } catch (err) {
      return handleResponse(err, false);
    }
  }

  return DELETE;
}
