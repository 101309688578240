import useAuth from "../../../../hooks/usefulHooks/useAuth";
import useUI from "../../../../hooks/usefulHooks/useUI";
import usePutHook from "../../../../hooks/httpHooks/usePutHook";

export default function Appearance() {
  const PUT = usePutHook();
  const { auth, setAuth } = useAuth();
  const { Interface, setInterface } = useUI();
  const { dark_mode } = auth?.user?.appearance;

  async function handleToggle(event, changes) {
    event.preventDefault();

    let { status, success, message } = await PUT(
      changes,
      "/api/account/settings/appearance",
      "application/json;charset=UTF-8",
      true
    );

    if (status === 200) {
      setInterface({
        isLoaded: true,
        alert: { success, message },
      });

      setInterface({ appearance: { ...Interface.appearance } });
      let updatedAppearance = { ...auth?.user?.appearance, ...changes };
      let updatedUser = auth.user;
      updatedUser.appearance = updatedAppearance;
      setAuth((rest) => ({ ...rest, user: updatedUser }));
    } else {
      setInterface({
        alert: { success, message },
        isLoaded: true,
      });
    }
    return;
  }

  let themes = [
    ["Auto", "auto"],
    ["System default", "system_default"],
    ["On", "on"],
    ["Off", "off"],
  ];

  return (
    <form id="appearance_form">
      <div id="darkMode">
        <div className="small_header">
          <span>Dark Mode</span>
        </div>

        <div id="dark_mode_category">
          {themes.map((e, i) => (
            <div key={i} className="dark_mode_options background">
              <label
                className={dark_mode === e[1] ? "selected_options" : ""}
                htmlFor={e[1]}
              >
                {e[0]}
              </label>
              <input
                id={e[1]}
                value={e[1]}
                checked={dark_mode === e[1]}
                onChange={(event) => handleToggle(event, { dark_mode: e[1] })}
                type="radio"
                name="dark_mode"
              />
            </div>
          ))}
        </div>
      </div>
    </form>
  );
}
