import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/usefulHooks/useAuth";
import useLogout from "../../../../hooks/httpHooks/useLogoutHook";

export default function NavDropdown() {
  let { auth } = useAuth();
  const LOGOUT = useLogout();
  return (
    <div
      className="fadeInOut-anim" 
      id="nav-dropdown"
    >
      <ul id="nav-dropdown-menu">
        <li id="current-user">
          <img src={auth?.user?.picture} alt="user_avatar" />
          <br />
          <span>{auth?.user?.username}</span>
          <br />
          <span>{auth?.user?.email}</span>
        </li>
        <li>
          <Link className="background" to="/manage-user-profile">
            <i className="fas fa-user-circle opt"></i>Manage Profile
          </Link>
        </li>
        <li>
          <Link className="background" to="/settings">
            <i className="fas fa-cog opt"></i>Settings
          </Link>
        </li>
        <li className="background">
          <button onClick={() => LOGOUT()} type="button">
            <i className="fas fa-sign-out-alt opt"></i>Logout
          </button>
        </li>
        
        <li className="app-tip" >
        <p>Tip : Install Strrings as a Web App for best user experience</p>
        </li>
      </ul>
    </div>
  );
}
