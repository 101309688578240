import useUI from "../usefulHooks/useUI";
import useGetHookEffect from "../httpHooks/useGetHookEffect";
import { useEffect } from "react";
import useChat from "../usefulHooks/useChat";

export default function useGetMessages(userID) {
  const { setInterface } = useUI();
  const { setChatOption, chatOption } = useChat();
  const isAlreadyfetched = !chatOption.messages.hasOwnProperty(userID);
  const { response, setResponse } = useGetHookEffect(
    `/api/${userID}/messages`,
    isAlreadyfetched,
    true
  );

  useEffect(() => {
    if (response) {
      if (response.status === 200) {
        let newMsgs = chatOption.newMessages[userID] || [];

        if (chatOption.newMessages[userID]) {
          // delete temporaray message from 'newMessages' object
          delete chatOption.newMessages[userID];
        }
        setChatOption((rest) => ({
          ...rest,
          newMessages: chatOption.newMessages,
          messages: {
            ...chatOption.messages,
            [userID]: [...response.allMessages, ...newMsgs],
          },
          isSaved: response.isSaved,
        }));
        setResponse(null);
        /* we need to set response null because we want messages of only selected user, 
        without this it returns the messages of previous selected user*/
      } else {
        setInterface({
          alert: { message: response?.message, success: false },
        });
      }
    }
    return () => setInterface({ isLoaded: true });
  }, [response, userID]);

  return;
}
