import React from "react";
import useUI from "../../../../hooks/usefulHooks/useUI";
import "./sideChats.scss";
import Spinner from "../../../usefulComponents/loadingComponents/spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import shortName from "../../../../modules/shortName";
import useUnreadAndLast from "../../../../hooks/utilityHooks/unread_last";
import useOnline from "../../../../hooks/utilityHooks/useOnline";
import useChat from "../../../../hooks/usefulHooks/useChat";
import useContacts from "../../../../hooks/componentHooks/getContacts";

export default function SideChats() {
  const navigate = useNavigate();
  const UNREAD_LAST = useUnreadAndLast();
  const { Interface, setInterface } = useUI();
  const {
    chatOption: {
      contacts: { allContacts },
    },
    setChatOption,
  } = useChat();
  const location = useLocation();
  const { isActive, isOnline } = useOnline();
  const { error, isLoaded } = useContacts();
  const currentChat = location.pathname;

  function handleSideChats() {
    let { collapse } = Interface;
    setInterface({ collapse: !collapse });
  }

  if (error) {
    setInterface({ alert: { message: error.message, succes: false } });
  }

  let isCollapse = Interface.collapse
    ? [
        "sideChats collapsed",
        "fa fa-arrow-circle-right",
        "expend",
        "onlineCircle",
      ]
    : ["sideChats expend", "fa fa-arrow-circle-left", "collapse", ""];

  const openChat = (user) => {
    setChatOption((rest) => ({ ...rest, currentUser: user }));
    navigate(`/chats/${user.userID}`, { replace: true });
    return;
  };

  return (
    <section className={`${isCollapse[0]}`}>
      <div id="sideChats-header">
        <span>Chats</span>
        <button
          title={isCollapse[2]}
          id="collapse_button"
          className="scale background"
          onClick={handleSideChats}
        >
          <i className={isCollapse[1]} aria-hidden="true"></i>
        </button>
      </div>

      <div className="side-chat-container">
        {isLoaded ? (
          <ul className="side-chat-list">
            {allContacts.length ? (
              allContacts.map((user) => {
                return (
                  <li
                    className={`side-chat-contact background ${
                      currentChat.includes(user?.userID) ? "current" : ""
                    }`}
                    key={user?.username}
                    title={user?.username}
                    onClick={() => openChat(user)} // To set user for chat
                  >
                    <img
                      className={`side-chat-avatar ${
                        isOnline(user) ? isCollapse[3] : ""
                      }`}
                      src={user?.picture}
                      alt="profile_img"
                    />

                    <div className="side-chat-info">
                      <div className="side-contact-name-count">
                        <p>{shortName(user.username, 8)}</p>
                        <span
                          className={
                            UNREAD_LAST(user.userID).getUnreadMessages
                              ? `side-unread_msg_count`
                              : `no-unread-msg`
                          }
                        >
                          {UNREAD_LAST(user.userID).getUnreadMessages}
                        </span>
                      </div>
                      <div className="side-contact-last-info">
                        <span
                          className={
                            isActive(user) === "Online"
                              ? "userOnline"
                              : isActive(user) === "Typing..."
                              ? "userTyping"
                              : ""
                          }
                        >
                          {isActive(user)}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <span className="no-side-contacts">You don't have any known contacts to display</span>
            )}
          </ul>
        ) : (
          <Spinner />
        )}
      </div>
    </section>
  );
}
