import useAxiosPrivate from "../usefulHooks/useAxiosPrivate";
import useAuth from "../usefulHooks/useAuth";
import useSocket from "../socketHooks/useSocket";

const useLogout = () => {
  const axiosPrivate = useAxiosPrivate();
  let { setAuth } = useAuth();
  let { socket, setSocket } = useSocket();

  let logout = async () => {
    try {
      socket.disconnect();
      setAuth(null);
      setSocket(null);
      await axiosPrivate.get("/api/logout");
    } catch (error) {
      console.error(error);
    }
  };

  return logout;
};

export default useLogout;
