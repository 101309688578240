import React, { useState, useEffect } from "react";
import useUI from "../../../../hooks/usefulHooks/useUI";
import ERROR from "../../../usefulComponents/informativeComponents/ERROR";
import useAuth from "../../../../hooks/usefulHooks/useAuth";
import useUpdate from "../../../../hooks/utilityHooks/useUpdate";
import useFieldChecker from "../../../../hooks/utilityHooks/fieldChecker";
import useGetHookEffect from "../../../../hooks/httpHooks/useGetHookEffect";
import useGetHook from "../../../../hooks/httpHooks/useGetHook";
import Ellipsis from "../../../usefulComponents/loadingComponents/ellipsis";
import Spinner from "../../../usefulComponents/loadingComponents/spinner";
import usePatchHook from "../../../../hooks/httpHooks/usePatchHook";

export default function Personal({ item, setItem }) {
  const PATCH = usePatchHook();
  const CHECK_FIELD = useFieldChecker();
  const UPDATED_FIELDS = useUpdate();
  const { response } = useGetHookEffect("/api/get/user/avatar");
  const GET = useGetHook();

  const { setInterface } = useUI();
  const { auth, setAuth } = useAuth();
  const { user } = auth;
  const [email, setEmail] = useState(user.email);
  const [input, setInput] = useState({
    username: user.username,
    about: user.about,
    status: user.status,
  });

  const [getValue, setValue] = useState({
    myAvatar: user.picture,
    isAvatarLoaded: true,
  });

  const { about, status } = input;
  const { inputError, isDisabled } = item;

  function handleChange(e) {
    const { name, value } = e.target;
    let field = { [name]: value };
    setInput((rest) => ({ ...rest, ...field }));
    let requireFields = CHECK_FIELD(UPDATED_FIELDS(field, user));
    setItem({ inputError: requireFields });
  }

  function handleEmail(e) {
    const { name, value } = e.target;
    let field = { [name]: value };
    setEmail(value);
    let requireFields = CHECK_FIELD(UPDATED_FIELDS(field, user));
    setItem({ inputError: requireFields });
  }

  async function submitData(e) {
    e.preventDefault();
    setItem({ loaded: false, inputError: null, error: null, isDisabled: true });

    const response = await PATCH(
      UPDATED_FIELDS(input, user), // only send updated fields to server,
      "/api/user/account/update/information",
      "application/json;charset=UTF-8",
      false
    );
    if (response.status === 200) {
      setAuth((rest) => ({
        ...rest,
        user: { ...user, ...input },
      }));
      setItem({
        loaded: true,
        inputError: null,
        error: null,
        isDisabled: false,
      });
      setInterface({
        alert: { success: true, message: response.message },
      });
    } else if (
      response.status === 400 &&
      response.hasOwnProperty("inputError")
    ) {
      setItem({
        inputError: response.inputError,
        loaded: true,
        isDisabled: false,
      });
    } else {
      setItem({ loaded: true, isDisabled: false });
      setInterface({
        alert: { success: false, message: response.message },
      });
    }
    return;
  }

  async function sendFile(e) {
    e.preventDefault();

    let file = { avatar: e.target.files[0] };

    setItem({ inputError: null, error: null, isDisabled: true });
    setValue((other) => ({ ...other, isAvatarLoaded: false }));

    const response = await PATCH(
      file,
      "/api/user/update-avatar",
      "multipart/form-data",
      false
    );
    if (response.status === 200) {
      let updatedUser = { ...auth.user, picture: response.updatedAvatar };
      setAuth((rest) => ({ ...rest, user: updatedUser }));
      setValue((other) => ({ ...other, isAvatarLoaded: true }));
      setItem({ isDisabled: false });
      setInterface({
        alert: {
          success: true,
          message: response?.message,
        },
      });
    } else {
      setValue((other) => ({ ...other, isAvatarLoaded: true }));
      setItem({ isDisabled: false });
      setInterface({
        alert: {
          success: false,
          message: response?.message,
        },
      });
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setValue((other) => ({ ...other, myAvatar: user.picture }));
      // To update avatar immediatly
    }
    return () => {
      isMounted = false;
    };
  }, [auth]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && response) {
      if (response.status === 200) {
        setValue((other) => ({ ...other, myAvatar: response.avatar }));
      }
    }

    return () => (isMounted = false);
  }, [response]);

  async function removeAvatar(e) {
    e.preventDefault();
    setItem({ inputError: null, error: null, isDisabled: true });
    setValue((other) => ({
      ...other,
      isAvatarLoaded: false,
    }));
    const data = await GET("/api/user/remove-avatar");
    if (data.status === 200) {
      let updatedUser = { ...auth.user, picture: data.user_default_avatar };
      setAuth((rest) => ({ ...rest, user: updatedUser }));
      setItem({ isDisabled: false });
      setValue((other) => ({
        ...other,
        isAvatarLoaded: true,
      }));
    } else {
      setValue((other) => ({ ...other, isAvatarLoaded: true }));
      setItem({ isDisabled: false });
      setInterface({
        alert: {
          success: false,
          message: data.message,
        },
      });
    }
  }

  async function verifyEmail(e) {
    e.preventDefault();
    setItem({ loaded: false, inputError: null, error: null, isDisabled: true });

    const response = await PATCH(
      UPDATED_FIELDS({ email }, user), // only send updated fields to server
      "/api/user/account/update/email",
      "application/json;charset=UTF-8",
      false
    );
    if (response.status === 202) {
      setItem({
        loaded: true,
        inputError: null,
        error: null,
        isDisabled: false,
      });
      setInterface({ isVerified: false });
    } else if (response.status === 400) {
      setItem({
        loaded: true,
        inputError: response.inputError,
        error: null,
        isDisabled: false,
      });
    } else {
      setItem({ loaded: true, inputError: null, isDisabled: false });
      setInterface({
        alert: { success: false, message: response.message },
      });
    }

    return;
  }

  let disabledBtn = user.picture.includes("user_default_avatar.jpg")
    ? [true, "disabled_btn"]
    : [getValue.isDisabled, ""];

  return (
    <div className="user_info">
      <form onSubmit={submitData}>
        <div className="personal_info">
          <div className="avatar_div">
            <div id="Avatar">
              <img
                className="avatar_img"
                src={getValue.myAvatar}
                alt="avatar"
              />

              {!getValue.isAvatarLoaded ? (
                <div id="avatar_loading">
                  <Spinner />
                </div>
              ) : (
                ""
              )}
            </div>
            {getValue.isAvatarLoaded ? (
              <div className="avatar_btn_div">
                <label
                  title="Change Avatar"
                  htmlFor="update_avatar"
                  className={`avatar_btn background `}
                >
                  <i className="fa fa-camera" aria-hidden="true"></i>
                </label>

                <button
                  type="button"
                  title="Remove Avatar"
                  disabled={disabledBtn[0]}
                  className={`avatar_btn background ${disabledBtn[1]}`}
                  onClick={removeAvatar}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>

                <a
                  title="View Avatar"
                  id="view_avatar"
                  href={getValue.myAvatar}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`avatar_btn background`}
                >
                  <i className="fas fa-expand-alt"></i>
                </a>

                <input
                  id="update_avatar"
                  onChange={sendFile}
                  type="file"
                  accept="image/*"
                  name="avatar"
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="info-div">
            <div className="cells_header">
              <h3>Personal Information</h3>
              <i className="fas fa-user-edit"></i>
            </div>

            <div className="input_wrapper">
              <label htmlFor="status_edit">
                <input
                  type="text"
                  id="edit_username"
                  name="username"
                  placeholder="Username"
                  value={input.username}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <span className="spanPlaceholder">Username</span>
              </label>
              <ERROR error={inputError?.username} />
            </div>
            <div className="input_wrapper ">
              <label htmlFor="status_edit">
                <input
                  id="status_edit"
                  type="input"
                  name="status"
                  maxLength={20}
                  placeholder="Status"
                  value={status}
                  onChange={handleChange}
                  aria-describedby="addon-wrapping"
                  disabled={isDisabled}
                />
                <span className="spanPlaceholder">Status</span>
              </label>

              <ERROR error={inputError?.status} />
            </div>
          </div>
          <div className="about">
            <label htmlFor="about_edit">About</label>
            <textarea
              id="about_edit"
              value={about}
              onChange={handleChange}
              rows="3"
              placeholder={about}
              name="about"
              disabled={isDisabled}
            ></textarea>
          </div>
        </div>

        {Object.keys(UPDATED_FIELDS(input, user)).length !== 0 ? (
          <div className="profile_save_button">
            {!item.loaded ? (
              <Ellipsis />
            ) : (
              <input
                type="submit"
                value="Save changes"
                className="myBtn"
                disabled={isDisabled}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </form>
      <form onSubmit={verifyEmail}>
        <div className="input_wrapper">
          <label htmlFor="status_edit">
            <input
              id="edit_email"
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleEmail}
              disabled={isDisabled}
            />
            <span className="spanPlaceholder">Email ID</span>
          </label>
          <ERROR error={inputError?.email} />
        </div>
        {UPDATED_FIELDS({ email }, user).hasOwnProperty("email") ? (
          <div className="profile_save_button">
            <p>A verification code will be sent to this email address.</p>
            {UPDATED_FIELDS({ email }, user).hasOwnProperty("email") &&
            !item.loaded ? (
              <Ellipsis />
            ) : (
              <input
                type="submit"
                value="Verify Email"
                className="myBtn"
                disabled={isDisabled}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}
