import React, { useState } from "react";
import "./manageProfile.scss";
import Personal from "./elements/personal";
import DangerZone from "./elements/dangerZone";
import DoVerification from "./updateVerification/verifyNewChanges";
import ManagerDropdown from "./elements/managerHeader";

export default function ManageProfile() {
  const [item, setItem] = useState({
    current_section: "Personal Information",
    inputError: null,
    error: null,
    loaded: true,
    isDisabled: false,
  });

  function openSection(section) {
    setItem((rest) => ({ ...rest, current_section: section }));
  }

  function changeState(newState) {
    setItem((rest) => ({ ...rest, ...newState }));
  }

  let sections = [
    {
      name: "Personal Information",
      icon: "Personal information.png",
      JSX: <Personal key="0" item={item} setItem={changeState} />,
    },
    {
      name: "Danger-Zone",
      icon: "deleteAc.png",
      JSX: <DangerZone key="2" item={item} setItem={changeState} />,
    },
  ];

  return (
    <section className="manage_profile_container">
      <ManagerDropdown setSection={openSection} item={item} />

      <div id="manage_profile_body" >
        <div className="editable_info">
          {sections.map((e) => (e.name === item.current_section ? e.JSX : ""))}
        </div>
        <DoVerification />
      </div>
    </section>
  );
}
