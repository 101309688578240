import React, { useState } from "react";
import "./verifyNewChanges.scss";
import ERROR from "../../../usefulComponents/informativeComponents/ERROR";
import Ellipsis from "../../../usefulComponents/loadingComponents/ellipsis";
import Countdown from "../../../usefulComponents/otherComponents/countdown";
import useVerificationHook from "../../../../hooks/componentHooks/verificationHook";
import Header from "../../../usefulComponents/ReuseableComponents/header";
import SixInput from "../../../usefulComponents/otherComponents/sixInput";
import useUI from "../../../../hooks/usefulHooks/useUI";

export default function DoVerification() {
  const { Interface, setInterface } = useUI();
 
  const { SUBMIT_CODE, RESEND_CODE } = useVerificationHook();
  const [item, setItem] = useState({
    error: null,
    isLoaded: { isSubmitted: true, isResent: true },
    isDisabled: { submit: false, resend: false },
    input: "",
    clearInput: false,
    suspend: { waiting: false, time: 0 },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setItem((rest) => ({
      ...rest,
      isDisabled: { submit: true, resend: true },
      isLoaded: { isSubmitted: false, isResent: true },
      error: null,
    }));
    const response = await SUBMIT_CODE({
      code: item.input,
    });

    if (response.status !== 200) {
      setItem((rest) => ({
        ...rest,
        error: response?.inputError?.code || {
          message: response?.message,
          status: response?.status,
        },
        isDisabled: {
          submit: false,
          resend: !item.isDisabled.resend ? false : true,
        },
        isLoaded: { isSubmitted: true, isResent: true },
      }));
    } else {
      setItem((rest) => ({
        ...rest,
        isDisabled: { submit: false, resend: false },
        isLoaded: { isSubmitted: true, isResent: true },
        suspend: { waiting: false, time: 0 },
        input: "",
        clearInput: true,
      }));
      setInterface({
        isVerified: true,
        alert: { message: response.message, success: true },
      });
    }
    return;
  }

  async function resendCode() {
    setItem((rest) => ({
      ...rest,
      isLoaded: { isSubmitted: true, isResent: false },
      isDisabled: { submit: true, resend: true },
      error: null,
    }));
    const { message, success, status, time } = await RESEND_CODE();

    if (success && status === 200) {
      setItem((rest) => ({
        ...rest,
        isLoaded: { isSubmitted: true, isResent: true },
        error: null,

        isDisabled: { submit: false, resend: true },
        suspend: { waiting: true, time },
      }));

      setInterface({ alert: { message, success: true } });
    } else if (!success && status !== 202) {
      return setItem((rest) => ({
        ...rest,
        isLoaded: { isSubmitted: true, isResent: true },
        isDisabled: { submit: false, resend: false },
        error: {
          message: message,
          status: status,
        },
      }));
    } else {
      setItem((rest) => ({
        ...rest,
        error: {
          message: message,
          status: status,
        },
        suspend: { waiting: false, time: 0 },
      }));
    }
  }

  function get_Otp_Value(value) {
    setItem((rest) => ({
      ...rest,
      input: value,
      error: null,
    }));
  }

  function reset() {
    setItem((rest) => ({
      ...rest,
      isDisabled: { submit: false, resend: false },
      isLoaded: { isSubmitted: true, isResent: true },
      suspend: { waiting: false, time: 0 },
    }));
  }

  function cancel() {
    setInterface({ isVerified: true });
    setItem((rest) => ({
      ...rest,
      clearInput: true,
    }));
    return reset();
  }

  let isVerification = Interface.isVerified ? "hide" : "";

  return (
    <section className={`verification_container ${isVerification} smooth-anim`}>
      <div className={`edit_verification components_background`}>
        <Header name={"Email verification"} link={false} backClick={cancel} />
        <form id="verifyChanges" onSubmit={handleSubmit}>
          <SixInput
            get_otp={get_Otp_Value} 
            clearInput={item.clearInput}
            setItem={setItem}
          />

          {item.suspend.waiting ? (
            <Countdown duration={item.suspend} cb={reset} />
          ) : !item.isLoaded.isResent ? (
            <Ellipsis />
          ) : (
            <button
              type="button"
              disabled={item.isDisabled.resend}
              onClick={resendCode}
              className={`resend_code ${
                item.isDisabled.resend ? `disabled_btn` : ""
              }`}
            >
              Resend verification email
            </button>
          )}

          {item.error ? <ERROR error={item.error} /> : ""}

          {!item.isLoaded.isSubmitted ? (
            <Ellipsis />
          ) : (
            <input
              disabled={item.isDisabled.submit}
              type="submit"
              className={`myBtn ${
                item.isDisabled.submit ? `disabled_btn` : ""
              }`}
              value="Verify"
            />
          )}
        </form>
      </div>
    </section>
  );
}
