import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../../App.scss";
import Ellipsis from "../../usefulComponents/loadingComponents/ellipsis";
import ERROR from "../../usefulComponents/informativeComponents/ERROR";
import VerifyUser from "../verification/VerifyUser";
import AnimBackground from "../../usefulComponents/otherComponents/svgBackground";
import usePostHook from "../../../hooks/httpHooks/usePostHook";
import useAuth from "../../../hooks/usefulHooks/useAuth";

export default function Login() {
  const POST = usePostHook(),
    navigate = useNavigate(),
    location = useLocation(),
    from = location.state?.from?.pathname || "/",
    { setAuth } = useAuth(),
    [visibility,setVisibility] = useState(false),
    [item, setItem] = useState({
      email: "",
      password: "",
      error: null,
      isLoaded: true,
      verifyNow: false,
    });


  const { email, password, error, isLoaded, verifyNow } = item;

  function changeState(changes) {
    setItem((rest) => ({ ...rest, ...changes }));
    return;
  }
  function handleChange(e) {
    const { name, value } = e.target;
    changeState({ [name]: value, error: null });
  }

  async function submitData(e) {
    e.preventDefault();
    changeState({ isLoaded: false, error: null });

    const { user, accessToken, socketToken, status, message } = await POST(
      { email, password },
      "/signin",
      false
    );

    if (status === 200) {
      setAuth({ user, accessToken, socketToken });
      navigate(from, { replace: true });
    } else if (status === 202) {
      changeState({ verifyNow: true, isLoaded: true });
    } else {
      changeState({
        error: {
          message,
          status,
        },
        isLoaded: true,
      });
    }
  }

  let emailError =
    error && error.status === 404 ? ["input_error", error] : ["", null];
  let passwordError =
    error && error.status === 401 ? ["input_error", error] : ["", null];
  let otherError =
    error && error.status !== 404 && error.status !== 401 ? error : null;

    const handleVisibility = ()=> setVisibility(!visibility)

    let changeVisibility = visibility ? "text" : "password"

  return (
    <section className="auth-container">
      <div id="signin" className="auth-box glass layers-anim">
        <div className="authHeader">
          <div id="appNameAndLogo">
            <img
              src="https://d34ct18kvr7jx3.cloudfront.net/assets/logo/logo96.png"
              alt="logo"
            />
            <h2>Strrings</h2>
          </div>
          <p>Sign in</p>
        </div>

        <div className="authInputBox">
          <form className="auth-form" onSubmit={submitData}>
            <div className="textInputs">
              <div className="input_wrapper">
                <label htmlFor="email">
                  <input
                    name="email"
                    onChange={handleChange}
                    value={email}
                    className={emailError[0]}
                    type="email"
                    id="email"
                    placeholder="Email"
                    required
                  />
                  <span className="spanPlaceholder">Email</span>
                </label>

                <ERROR error={emailError[1]} />
              </div>

              <div className="input_wrapper">
                <label htmlFor="password">
                  <input
                    type={changeVisibility}
                    id="password"
                    name="password"
                    className={passwordError[0]}
                    value={password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                  />
                  <span className="spanPlaceholder">Password</span>
                </label>
                <ERROR error={passwordError[1]} />
              </div>
              <div className="pv">
                <input
                  type="checkbox"
                  name="password_visibility"
                  id="password_visibility"
                  onClick={handleVisibility}
                />
                <label htmlFor="password_visibility">Show Password</label>
              </div>
              <div id="forgotPassword">
                <Link className="link" to="/password/recovery">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <ERROR error={otherError} />
            <div className="inputsBtn">
              <div id="signup_Btn" className="next create-account">
                {isLoaded ? (
                  <p>
                    <Link to="/signup" replace>
                      Create account
                    </Link>
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="next">
                {!isLoaded ? (
                  <Ellipsis />
                ) : (
                  <input
                    id="signin_Btn"
                    className="continueBtn"
                    type="submit"
                    value="Continue"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <VerifyUser
        verificationType={"two-step-verification"}
        verifyNow={verifyNow}
        cancelVerification={setItem}
      />

      <AnimBackground
        color0={"#322DED"}
        color1={"#B537F0"}
        color2={"#F241EF"}
        color3={"#3C37F0"}
        color4={"#f33775"}
      />
    </section>
  );
}
