import React from "react";

export default function ERROR({error ,align}) {
 
  let setErrors = !error
    ? error
    : Array.isArray(error)
    ? error
    : [{ message: error?.message }];

  return (
    <>
      <div className="ERROR">
        {setErrors
          ? setErrors.map((e, i) => (
              <div style={{textAlign:align}} key={i}>
                <span><i className="fa fa-exclamation-circle" aria-hidden="true"></i>{e.message}</span>
              </div>
            ))
          : ""}
      </div>
    </>
  );
}
