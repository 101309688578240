import { useNavigate } from "react-router-dom";
import { ReactComponent as Error404 } from "../../svg/error404.svg";
export default function Error() {
  const navigate = useNavigate();

  return (
    <div id="routeError">
      <Error404 />
      <button className="myBtn" onClick={() => navigate(-1, { replace: true })}>
        Go back
      </button>
    </div>
  );
}
