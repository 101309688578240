import { useEffect, useState } from "react";
import useAxiosPrivate from "../usefulHooks/useAxiosPrivate";
import handleResponse from "../../modules/responseHandler";
import useUI from "../usefulHooks/useUI";

/*
The 'requestNow' parameter prevent to make Get request
 everytime a component render if the data already fetched and stored in the context (Not in component state)
 and the 'showProgress' parameter used to turn on and off loading spinner
*/

const useGetHookEffect = (url, requestNow = true, showProgress = false) => {
  const axiosPrivate = useAxiosPrivate();
  const { setInterface } = useUI();
  const [response, setResponse] = useState(null);

  useEffect(() => {
    let timeoutId;
    const abortController = new AbortController();

    async function makeGetRequest() {
      const signal = abortController.signal;
      timeoutId = setTimeout(() => {
        abortController.abort();
      }, 30000);

      setInterface({ isLoaded: !showProgress });

      try {
        const res = await axiosPrivate.get(url, { signal });
        let data = handleResponse(res, true);
        setResponse(data);
      } catch (error) {
        let err = handleResponse(error, false);
        if (err.message !== "canceled") {
          setResponse(err);
        }
      } finally { 
        clearTimeout(timeoutId); // Clear the timeout if the request succeeds
        abortController.abort(); // Abort the request
      }
    }

    if (requestNow) {
      makeGetRequest();
    } else {
      setResponse(null);
    }

    return () => {
      if (requestNow) {
        clearTimeout(timeoutId); // Clear the timeout if the request succeeds
        abortController.abort(); // Cancel the request when the component unmounts
      }
    };
  }, [url]);

  return { response, setResponse };
};

export default useGetHookEffect;
