import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCF_Um5g2s2M4U-2gKuSAK6GvLVQryZRsE",
  authDomain: "strrings-29a9e.firebaseapp.com",
  projectId: "strrings-29a9e",
  storageBucket: "strrings-29a9e.appspot.com",
  messagingSenderId: "252292433754",
  appId: "1:252292433754:web:3f5975fab968d203e6d563",
  measurementId: "G-EYZJ0GQKXM"
});

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
const messaging = getMessaging(firebaseApp);
// [END messaging_init_in_sw_modular]

export default messaging;
