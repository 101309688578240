import { useState } from "react";
import useUI from "../../../../hooks/usefulHooks/useUI";
import usePutHook from "../../../../hooks/httpHooks/usePutHook";
import useAuth from "../../../../hooks/usefulHooks/useAuth";
import { getToken, deleteToken, getMessaging } from "firebase/messaging";
import messaging from "../../../../modules/firebase";
import getNotificationPermission from "../../../../modules/notificationPermission";

export default function Notifications() {
  const { setInterface } = useUI();
  const PUT = usePutHook();
  const {
    auth: {
      user: { notifications },
    },
    setAuth,
  } = useAuth();
  const NOTIFICATION =
    notifications.notification_permission.permission &&
    Notification.permission === "granted";
  const [permission, setPermission] = useState(NOTIFICATION);
  const [notification, setNotification] = useState({
    message_notification: notifications.message_notification,
  });

  async function enableNotification() {
    const perm = await getNotificationPermission(
      notifications.notification_permission.permission
    );
    let response = null;

    if (perm && !permission) {
      try {
        setInterface({ isLoaded: false });
        const token = await getToken(messaging, {
          vapidKey:
            "BO3muAR1fGRI0eol1vBh0WMfyD_Rj574XrBjTk-X-X1CHOg-0YRss_SZaH3AKtyvq2tWvSBp2DCcVklL1yjhuJE",
        });
        if (token) {
          response = await PUT(
            {
              notification_permission: { permission: true, token },
            },
            "/api/account/settings/notifications-permission",
            "application/json;charset=UTF-8",
            true
          );
        }
      } catch (error) {
        response = {
          status: 500,
          success: false,
          message: "Notification couldn't be enabled!",
        };
      } finally {
        setInterface({
          alert: { success: response.success, message: response.message },
          isLoaded: true,
        });
      }
    } else {
      response = await PUT(
        {
          notification_permission: { permission: false, token: "" },
        },
        "/api/account/settings/notifications-permission",
        "application/json;charset=UTF-8",
        true
      );
      let messaging = getMessaging();
      await deleteToken(messaging);
    }

    const { status, success, message, user } = response;

    setPermission(!permission && perm !== permission);

    if (status === 200) {
      setInterface({
        alert: { success, message },
        isLoaded: true,
      });

      setAuth((rest) => ({ ...rest, user }));
    } else {
      setInterface({
        isLoaded: true,
        alert: { success: false, message },
      });
    }
    return;
  }

  async function handleToggle(e) {
    let id = e.target.id;
    let changes = { ...notification, [id]: !notification[id] };

    let { status, success, message, user } = await PUT(
      changes,
      "/api/account/settings/notifications",
      "application/json;charset=UTF-8",
      true
    );

    if (status === 200) {
      setInterface({
        isLoaded: true,
        alert: { success, message },
      });
      setAuth((rest) => ({ ...rest, user }));
      setNotification((rest) => ({ ...rest, [id]: !notification[id] }));
    } else {
      setInterface({
        isLoaded: true,
        alert: { success: false, message },
      });
    }
  }

  let feature = NOTIFICATION
    ? [false, "feature_available"]
    : [true, "feature_not_available"];

  let msgNotification = notification.message_notification && NOTIFICATION;
  let notificationPermission = permission && NOTIFICATION;

  return (
    <form
      className="security_form fc-center-between"
      id="submit_security_options"
    >
      <div className="settings_options">
        <ul>
          <li className="notification-notice">
            <p>
              If notifications aren't working, toggle them off and on again.
            </p>
          </li>

          <li className="wide-list-btn feature_available">
            <label
              className="feature_available"
              htmlFor="notification_permission"
            >
              Notification Permmision
            </label>
            <input
              id="notification_permission"
              className="toggle_switch"
              checked={notificationPermission}
              onChange={enableNotification}
              type="checkbox"
            />
          </li>

          <li className={`wide-list-btn ${feature[1]}`}>
            <label className={`${feature[1]}`} htmlFor="message_notification">
              Message Notification
              <br />
              <span id="feature_note">
                This feature requires the Notification permission to be enabled.
              </span>
            </label>
            <input
              disabled={feature[0]}
              id="message_notification"
              className="toggle_switch"
              onChange={handleToggle}
              checked={msgNotification}
              type="checkbox"
            />
          </li>
        </ul>
      </div>
    </form>
  );
}
