import React from "react";
import { Navigate, Outlet,useLocation, useParams} from "react-router-dom";

export default function UserVerification() {
  let location = useLocation();
  let {token} = useParams() 
 
  return token ? (
    <Outlet />
  )  : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}


