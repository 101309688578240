export default function findUnknownContactMsg(unreadMessages, contacts) {
  let count = false;
  for (let userID in unreadMessages) {
    if (contacts.length) {
      for (let contact of contacts) {
        if (!contact.hasOwnProperty(userID) && unreadMessages[userID].length) {
          count = true;
          break;
        }
      }
    } else {
      count = unreadMessages[userID].length > 0;
      break;
    }
  }

  return count;
}
