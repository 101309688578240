import { useState } from "react";
import useUI from "../../../../hooks/usefulHooks/useUI";
import "./navbar.scss";
import SearchBar from "./searchBar";
import NavDropdown from "./navDropdown";
import useChat from "../../../../hooks/usefulHooks/useChat";
import findUnknownContactMsg from "../../../../modules/unknownContactMsg";
import { useEffect } from "react";

export default function Navbar() {
  const { Interface, setInterface } = useUI();
  const [unknownContact, setUnknownContact] = useState(false);
  const { chatOption } = useChat();
  function handleSearchMode() {
    let disableProfile = Interface.show_Profile ? false : false;
    setInterface({
      searchMode: !Interface.searchMode,
      show_Profile: disableProfile,
    });
  }
 
  function handleDropdown(e) {
    e.stopPropagation();
    setInterface({
      show_Options: !Interface.show_Options,
    });
  }

  let setDropdown = Interface.show_Options ? ["showOptions"] : ["hideOptions"];

  useEffect(() => {
    let isUnknown = findUnknownContactMsg(
      chatOption.unreadMessage,
      chatOption.contacts.allContacts
    );
    setUnknownContact(isUnknown);
  }, [chatOption.unreadMessage]);

  return (
    <>
      <nav className={`navbar`}>
        {!Interface.searchMode ? (
          <div className="navbar-items">
            <div className="App_name">
              <h1>Strrings</h1>
            </div>

            <div className={`options_slider ${setDropdown[0]}`}>
              {!Interface.show_Options ? (
                <div id="option-wrapper">
                  <button
                    onClick={handleSearchMode}
                    className={
                      unknownContact
                        ? "enable-search background new-user-msg"
                        : "enable-search background"
                    }
                  >
                    <i className="fas fa-search"></i>
                  </button>

                  <button
                    onClick={handleDropdown}
                    className="option-bar background"
                    type="button"
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleDropdown}
                  className="arrow_btn"
                  type="button"
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
            </div>
          </div>
        ) : (
          <SearchBar handleSearchMode={handleSearchMode} />
        )}
      </nav>
      {Interface.show_Options ? <NavDropdown /> : ""}
    </>
  );
}
