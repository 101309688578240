export default function EmailSuccess({ email }) {
  return (
    <div className="reset glass">
      <div>
        <h3>
          Password reset link has been sent to your registered email address.
        </h3>
        <p>
          Please follow the instructions in the Email to reset your password. If
          you do not receive an E-mail, check your spam mail box.
        </p>
        <span>
          <strong>{email}</strong>
        </span>
      </div>

      <a className="myBtn" href="https://mail.google.com/mail/u/0/#inbox">
        Go to inbox
        <i
          style={{ marginLeft: "5px" }}
          className="fa fa-inbox"
          aria-hidden="true"
        ></i>
      </a>
    </div>
  );
}
