import "./loaders.scss"

export default function Spinner() {
  return (
    <>
    <div className="isLoading">
    <span className="spinner-loader"></span>
      </div>
    </>
  );
}
