import React, { useState, useEffect } from "react";
import "./settings.scss";
import Header from "../../usefulComponents/ReuseableComponents/header";
import Appearance from "./elements/appearance";
import Security from "./elements/security";
import Notifications from "./elements/notification";
import Feedback from "./elements/feedback";
import BlockedContacts from "./elements/blocked_contacts";
import { useNavigate, useLocation } from "react-router-dom";
import useUI from "../../../hooks/usefulHooks/useUI";
import About from "./elements/about";

export default function Settings() {
  const { Interface, setInterface } = useUI();
  const {
    appearance: { components_background },
  } = Interface;
  const navigate = useNavigate();
  const location = useLocation();

  const [setting, modifySettings] = useState({
    current_settings: "Appearance",
    isSettingsOpen: false,
  });

  const elements = [
    {
      name: "Appearance",
      icon: <i className="fa-solid fa-palette icon_inside_btn"></i>,
      JSX: <Appearance key="0" />,
    },
    {
      name: "Notifications",
      icon: <i className="fa-solid fa-bell icon_inside_btn"></i>,
      JSX: <Notifications key="1" />,
    },
    {
      name: "Security",
      icon: <i className="fa-solid fa-lock icon_inside_btn"></i>,
      JSX: <Security key="2" />,
    },
    {
      name: "Blocked Contacts",
      icon: <i className="fa-solid fa-ban icon_inside_btn"></i>,
      JSX: <BlockedContacts key="3" />,
    },
    // { name: "Shortcuts", icon: "shortcut.png", JSX: <Shortcuts key="3" /> }, Coming soon 😎😎😎
    {
      name: "Feedback",
      icon: <i className="fa-solid fa-message icon_inside_btn"></i>,
      JSX: <Feedback key="5" />,
    },
    {
      name: "About",
      icon: <i className="fa-solid fa-circle-info icon_inside_btn"></i>,
      JSX: <About key="6" />,
    },
  ];

  useEffect(() => {
    let isResized = true;
    if (isResized) {
      if (Interface.mobile_view) {
        modifySettings((rest) => ({
          ...rest,
          current_settings: "",
        }));
      } else {
        modifySettings((rest) => ({
          ...rest,
          current_settings: "Appearance",
        }));
      }
    }
    return () => (isResized = false);
  }, []);

  function openSettings(command) {
    navigate(`/settings/#${command.toLowerCase().replace(" ", "-")}`, {
      replace: true,
    });
    modifySettings((rest) => ({
      ...rest,
      current_settings: command,
    }));
    if (Interface.mobile_view) {
      setInterface({ backToSettings: true });
      modifySettings((rest) => ({ ...rest, isSettingsOpen: true }));
    }
  }

  function back() {
    let hash = location.hash;
    if (/#[\w-_]+$/.test(hash) && Interface.mobile_view) {
      navigate(hash.replace(/\/*#\w+$/, ""), { replace: true });
      modifySettings((rest) => ({ ...rest, isSettingsOpen: false }));
    } else {
      navigate(hash.replace(/\/\w+$/, ""), { replace: true });
      if (Interface.backToSettings && !Interface.mobile_view) {
        setInterface({ backToSettings: false });
      }
    }
  }

  let settings_layout = Interface.mobile_view ? "mobile_view" : "";
  let canGoBack = Interface.backToSettings ? "show" : "hide";
  let hide = setting.isSettingsOpen
    ? ["hide_layout", "show_layout"]
    : ["show_layout", "hide_layout"];

  return (
    <>
      <section className={`settings`}>
        <Header name={"Settings"} link={true} />

        <div className={`settings_window ${settings_layout}`}>
          <div className={`setting_options components_background ${hide[0]}`}>
            <div className="Btns_list_div">
              <ul className="Btns_list">
                {elements.map((e, i) => {
                  return (
                    <li className="modified_settings_Btn" key={i}>
                      <button
                        className={`thisBtn modifiedThisBtn background ${
                          setting.current_settings === e.name
                            ? "current_setting"
                            : ""
                        }`}
                        onClick={() => openSettings(e.name)}
                      >
                        {e.icon}
                        <span>{e.name}</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div
            className={`selected_settings ${components_background} ${hide[1]}`}
          >
            <div className="settings_header">
              <div>
                <button
                  disabled={!Interface.backToSettings}
                  className={`${canGoBack} arrow_btn`}
                  onClick={back}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                <h2>{setting.current_settings}</h2>
              </div>
            </div>

            <div id="opened_setting">
              {elements.map((e) =>
                setting.current_settings === e.name ? e.JSX : ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
