import "./contacts.scss";
import Spinner from "../../../usefulComponents/loadingComponents/spinner";
import useUI from "../../../../hooks/usefulHooks/useUI";
import ContactsHeader from "./components/contactsHeader";
import Profile from "../profile/profile";
import SearchLayout from "./components/searchLayout";
import ContactList from "./components/contactList";
import useBlockedContacts from "../../../../hooks/componentHooks/getBlockedContacts";
import useContacts from "../../../../hooks/componentHooks/getContacts";
import useChat from "../../../../hooks/usefulHooks/useChat";

export default function Contacts() {
  const { error, isLoaded } = useContacts();
  const { Interface } = useUI();
  const {
    chatOption: { contacts },
  } = useChat();

  useBlockedContacts();
  let info = null;
  if (error) {
    info = ["error", error?.message];
  } else if (contacts.isFetched && !contacts.allContacts.length) {
    info = [
      "noContacts",
      "You don't have any known contacts to chat with. Please click the search icon 🔍 to find contacts for chatting.",
    ];
  }

  let isSearchModeOn = Interface.searchMode ? "hide" : "";

  return (
    <>
      <section className={`chats_container`}>
        <SearchLayout />
        <ContactsHeader isSearchModeOn={isSearchModeOn} />
        <div className={`chats ${isSearchModeOn}`}>
          {Interface.show_Profile ? <Profile /> : ""}
          {isLoaded ? (
            <ContactList contacts={contacts.allContacts} error={info} />
          ) : (
            <Spinner />
          )}
        </div>
      </section>
    </>
  );
}
