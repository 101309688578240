import { useEffect, useState } from "react";
import useGetHookEffect from "../httpHooks/useGetHookEffect";
import useUI from "../usefulHooks/useUI";
import useChat from "../usefulHooks/useChat";
 
export default function useGetUser(userID) {
  const [userToChat, setUserToChat] = useState(null);
  const { setInterface } = useUI();
  const {chatOption} = useChat()
  let isUserReadyToChat = !chatOption.currentUser // only make get request if user is not available
  const {response} = useGetHookEffect(`/api/user/${userID}`, isUserReadyToChat, true);

  useEffect(() => {
    if (response) {
      if (response?.status === 200) {
        setUserToChat(response.user);
      } else {
        setInterface({
          alert: { message: response.message, success: false },
        });
      }
    }
    return () => setInterface({ isLoaded: true });
  }, [response]);

  return { userToChat };
}
