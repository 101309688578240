import getLastSeenMessage from "../../modules/lastSeen";
import useChat from "../usefulHooks/useChat";

export default function useOnline() {
  const {
    chatOption: { onlineUsers, isUserTyping },
  } = useChat();

  function isActive(user) {
    if (onlineUsers.includes(user.userID) && isUserTyping.includes(user.userID)) {
      return "Typing...";
    } else if (onlineUsers.includes(user.userID)) {
      return "Online";
    }
    return getLastSeenMessage(user.last_seen);
  }

  function isOnline(user) {
    return onlineUsers.includes(user.userID) ? true : false;
  }

  function isTyping(user) { 
    return onlineUsers.includes(user.userID) && isUserTyping.includes(user.userID) ? true : false;
  }

  return { isActive, isOnline, isTyping };
}
