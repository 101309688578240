import React from "react";
import useUI from "../../../../../hooks/usefulHooks/useUI";

export default function ContactsHeader({ isSearchModeOn }) {
  const { Interface } = useUI();

  return (
    <div className={`contacts-header ${isSearchModeOn}`}>
      <h5>{Interface.home_view}</h5>
    </div>
  );
}
