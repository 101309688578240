import { useEffect } from "react";

export default function useDebounceHook(func, input) {
  let timerId; // Define timerId outside of debounce function

  // Debounce function definition
  function debounce(cb) {
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        cb.apply(this, args);
      }, 500);
    };
  }

  const debouncedSendRequest = debounce(func);

  useEffect(() => {
    // Execute the debounced function after component renders

    debouncedSendRequest(input);

    // Clean-up function
    return () => {
      // Clear any pending debounced function calls
      clearTimeout(timerId);
    };
  }, [input]); // Empty dependency array ensures the effect runs only once after initial render
}
