import { useEffect } from "react";
import useChat from "../usefulHooks/useChat";
import useUI from "../usefulHooks/useUI";

const useSocketEventHandlers = (socket) => {
  const { chatOption, setChatOption } = useChat();
  const { setInterface } = useUI();

  useEffect(() => {
    const receiveEvent = ({ sender, receiver, message }) => {
      if (sender === receiver) {
        // if sender is blocked by receiver
        setInterface({ alert: { success: false, message: message.content } });
      } else {
        let isUnreadMessage = chatOption.unreadMessage?.[sender] || [];
        if (!message.read) {
          /*If message is unread so update unread messages array for blue popup counter and last message 🔟*/
          let updateUnreadMsg = [
            ...isUnreadMessage,
            { sender, receiver, message },
          ];

          let oldMessages = chatOption.messages?.[sender] || [];

          if (chatOption.messages[sender]) {
            // if old messages fetched by API
            setChatOption((rest) => ({
              ...rest,
              messages: {
                ...chatOption.messages,
                [sender]: [...oldMessages, { sender, receiver, message }],
              },
              lastMessage: {
                ...chatOption.lastMessage,
                [sender]: { sender, receiver, message },
              },
              unreadMessage: {
                ...chatOption.unreadMessage,
                [sender]: updateUnreadMsg,
              },
            }));
          } else {
            // if old messages not fetched by API
            let oldMessages = chatOption.newMessages?.[sender] || [];
            setChatOption((rest) => ({
              ...rest,
              newMessages: {
                ...chatOption.newMessages,
                [sender]: [...oldMessages, { sender, receiver, message }],
              },
              lastMessage: {
                ...chatOption.lastMessage,
                [sender]: { sender, receiver, message },
              },
              unreadMessage: {
                ...chatOption.unreadMessage,
                [sender]: updateUnreadMsg,
              },
            }));
          }
        } else {
          let oldMessages = chatOption.messages[sender];
          setChatOption((rest) => ({
            ...rest,
            messages: {
              ...chatOption.messages,
              [sender]: [...oldMessages, { sender, receiver, message }],
            },
            lastMessage: {
              ...chatOption.lastMessage,
              [sender]: { sender, receiver, message },
            },
          }));
        }
      }
    };

    const updateMessageStatus = (data) => {
      /* To updates realtime messages when both sides chats are open 💬*/

      let currentUserMessages = chatOption.messages[data.receiver];
      currentUserMessages[currentUserMessages.length - 1] = data;
      let receiver = data.receiver;

      setChatOption((rest) => ({
        ...rest,
        messages: { ...chatOption.messages, [receiver]: currentUserMessages },
        lastMessage: {
          ...chatOption.lastMessage,
          [receiver]: data,
        },
      }));
    };

    const typingEvent = (user) => {
      setChatOption((rest) => ({
        ...rest,
        isUserTyping: [...chatOption.isUserTyping, user],
      }));
    };

    const notTypingEvent = (user) => {
      let indexOfUser = chatOption.isUserTyping.indexOf(user);
      chatOption.isUserTyping.splice(indexOfUser, 1);
      setChatOption((rest) => ({
        ...rest,
        isUserTyping: chatOption.isUserTyping,
      }));
    };

    const updateSenderMessages = ({ updatedReadStatus, sender }) => {
      /*To update all sent messages status when receiver open the chat ✔*/
      if (updatedReadStatus && updatedReadStatus.length) {
        let lastMsg = updatedReadStatus[updatedReadStatus.length - 1];
        let updateMsg = chatOption.messages[sender] || [];

        for (let i = updateMsg.length - 1; i >= 0; i--) {
          for (let j = updatedReadStatus.length - 1; j >= 0; j--) {
            if (
              updateMsg[i].message.messageID ===
              updatedReadStatus[j].message.messageID
            ) {
              updateMsg[i] = updatedReadStatus[j];
              updatedReadStatus.pop();
              break;
            }
          }
          if (!updatedReadStatus.length) {
            break;
          }
        }

        let otherUserMessages = chatOption.messages;
        if (otherUserMessages.hasOwnProperty(sender)) {
          // Only set updated messages if user messages of that user are fetched
          setChatOption((rest) => ({
            ...rest,
            messages: { ...otherUserMessages, [sender]: updateMsg },
            lastMessage: {
              ...chatOption.lastMessage,
              [sender]: lastMsg,
            },
          }));
        } else {
          setChatOption((rest) => ({
            ...rest,
            //  messages: { ...otherUserMessages, [sender]: updateMsg },
            lastMessage: {
              ...chatOption.lastMessage,
              [sender]: lastMsg,
            },
          }));
        }
      }
      /*To update only last sent message in (chatOption.lastMessage) contacts or chats list when reciver not open sender chat 🧾*/
    };

    const updateReceiverMessages = (receiver) => {
      /*To clear all unread messages when receiver open the chat ✔*/

      setChatOption((rest) => ({
        ...rest,
        unreadMessage: {
          ...chatOption.unreadMessage,
          [receiver]: [],
        },
      }));
    };

    function deliveredStatusToSender(messages) {
      if (messages && messages.length) {
        let lastMsg = messages[messages.length - 1];
        let updateMsg = chatOption.messages[lastMsg.receiver] || [];

        for (let i = updateMsg.length - 1; i >= 0; i--) {
          for (let j = messages.length - 1; j >= 0; j--) {
            if (
              updateMsg[i].message.messageID === messages[j].message.messageID
            ) {
              updateMsg[i] = messages[j];
              messages.pop();
              break;
            }
          }
          if (!messages.length) {
            break;
          }
        }

        /*To only updatelast sent message in (chatOption.lastMessage) contacts or chats list when reciver not open sender chat 🧾*/

        let otherUserMessages = chatOption.messages;
        if (otherUserMessages.hasOwnProperty(lastMsg.receiver)) {
          // Only set updated messages if user messages of that user are fetched
          setChatOption((rest) => ({
            ...rest,
            messages: { ...otherUserMessages, [lastMsg.receiver]: updateMsg },
            lastMessage: {
              ...chatOption.lastMessage,
              [lastMsg.receiver]: lastMsg,
            },
          }));
        } else {
          setChatOption((rest) => ({
            ...rest,
            lastMessage: {
              ...chatOption.lastMessage,
              [lastMsg.receiver]: lastMsg,
            },
          }));
        }
      }
    }

    function removeDeletedMessageFromState({
      contactID,
      success,
      message,
      deletedMsg,
    }) {
      let unDeletedMessages = [];
      let currentUserMessages = chatOption.messages[contactID];
      for (let i = 0, len = currentUserMessages.length; i < len; i++) {
        if (!deletedMsg.includes(currentUserMessages[i].message.messageID)) {
          unDeletedMessages.push(currentUserMessages[i]);
        }
      }

      setInterface({ alert: { success, message } });
      setChatOption((rest) => ({
        ...rest,
        messages: { ...chatOption.messages, [contactID]: unDeletedMessages },
        lastMessage: {
          ...chatOption.lastMessage,
          [contactID]: unDeletedMessages[unDeletedMessages.length - 1],
        },
      }));
    }

    function clearAllMessages({ contactID, success, message }) {
      if (success) {
        setChatOption((rest) => ({
          ...rest,
          messages: { ...chatOption.messages, [contactID]: [] },
          lastMessage: { ...chatOption.lastMessage, [contactID]: {} },
        }));
      }
      setInterface({ alert: { success, message } });
    }

    function userBlocked(data) {
      if (data.success) {
        let { blocked, isFetched } = chatOption.blockedContacts;
        let getContacts = chatOption.contacts.allContacts;
        blocked.push(data.blockedUser);

        for (let i = 0, len = getContacts.length; i < len; i++) {
          if (data.blockedUser.userID === getContacts[i].userID) {
            getContacts.splice(i, 1);
            break;
          }
        }

        setChatOption((rest) => ({
          ...rest,
          contacts: { ...chatOption.contacts, allContacts: getContacts },
          blockedContacts: { isFetched, blocked },
        }));
      }

      setInterface({
        alert: { success: data.success, message: data.message },
      });
    }

    function userUnblocked(data) {
      if (data.success) {
        let { blocked, isFetched } = chatOption.blockedContacts;
        let newBlocked = blocked;

        for (let i = 0, len = blocked.length; i < len; i++) {
          if (blocked[i].userID === data.contactID) {
            newBlocked.splice(i, 1);
            break;
          }
        }

        setChatOption((rest) => ({
          ...rest,
          contacts: { ...chatOption.contacts, isFetched: false },
          blockedContacts: { isFetched, blocked: newBlocked },
        }));
      }
      setInterface({
        alert: {
          success: data.success,
          message: data.message,
        },
      });
    }

    function addedToChats(data) {
      if (data.success) {
        let { isFetched, allContacts } = chatOption.contacts;
        let newContacts = allContacts;
        newContacts.push(data.contact);
        setChatOption((rest) => ({
          ...rest,
          isSaved: true,
          contacts: { isFetched, allContacts: newContacts },
        }));
      }
      setInterface({
        alert: {
          success: data.success,
          message: data.message,
        },
      });
    }

    function removeFromChats(data) {
      if (data.success) {
        let { isFetched, allContacts } = chatOption.contacts;
        let removeContact = allContacts;

        for (let i = 0, len = allContacts.length; i < len; i++) {
          if (allContacts[i].userID === data.contactID) {
            removeContact.splice(i, 1);
            break;
          }
        }

        setChatOption((rest) => ({
          ...rest,
          isSaved: false,
          contacts: { isFetched, allContacts: removeContact },
        }));
      }
      setInterface({
        alert: {
          success: data.success,
          message: data.message,
        },
      });
    }

    function updateLastSeen(data) {
      let { allContacts } = chatOption.contacts;
      for (let contact of allContacts) {
        if (contact.userID === data.userID) {
          contact.last_seen = data.lastSeen; // To update lastSeen immedately
          break;
        }
      }
    }

    function getLastMessages({ success, message, lastAndUnread }) {
      if (success) {
        let lastMsg = lastAndUnread?.lastMessage || {};
        let unreadMsg = lastAndUnread?.unreadMessage || {};

        setChatOption((rest) => ({
          ...rest,
          lastMessage: { ...chatOption.lastMessage, ...lastMsg },
          unreadMessage: { ...chatOption.unreadMessage, ...unreadMsg },
          isRecentMessagesFetched: true,
        }));
      } else {
        setChatOption((rest) => ({
          ...rest,
          isRecentMessagesFetched: true,
        }));
        setInterface({
          alert: {
            success: success,
            message: message,
          },
        });
      }
    }

    if (socket) {
      socket.on("receive", receiveEvent);
      socket.on("sender-typing", typingEvent);
      socket.on("sender-not-typing", notTypingEvent);
      socket.on("message-status-to-sender", updateMessageStatus);
      socket.on("update-receiver-messages", updateReceiverMessages);
      socket.on("update-sender-messages", updateSenderMessages);
      socket.on("deliveredStatusToSender", deliveredStatusToSender);
      socket.on("message-deleted", removeDeletedMessageFromState);
      socket.on("clear-all-messages", clearAllMessages);
      socket.on("user-blocked", userBlocked);
      socket.on("user-unblocked", userUnblocked);
      socket.on("user-added-to-chats", addedToChats);
      socket.on("user-removed-from-chats", removeFromChats);
      socket.on("user-last-seen", updateLastSeen);
      socket.on("get-last-messages", getLastMessages);
    }

    // Clean up event listeners when the component unmounts
    return () => {
      if (socket) {
        socket.off("receive", receiveEvent);
        socket.off("sender-typing", typingEvent);
        socket.off("sender-not-typing", notTypingEvent);
        socket.off("message-status-to-sender", updateMessageStatus);
        socket.off("update-sender-messages", updateSenderMessages);
        socket.off("update-receiver-messages", updateReceiverMessages);
        socket.off("deliveredStatusToSender", deliveredStatusToSender);
        socket.off("message-deleted", removeDeletedMessageFromState);
        socket.off("clear-all-messages", clearAllMessages);
        socket.off("user-blocked", userBlocked);
        socket.off("user-unblocked", userUnblocked);
        socket.off("user-added-to-chats", addedToChats);
        socket.off("user-removed-from-chats", removeFromChats);
        socket.off("user-last-seen", updateLastSeen);
        socket.off("get-last-messages", getLastMessages);
      }
    };
  }, [
    socket,
    chatOption.messages,
    chatOption.lastMessage,
    chatOption.unreadMessage,
  ]);
};

export default useSocketEventHandlers;
