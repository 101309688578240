import { useEffect } from "react";
import useGetHookEffect from "../httpHooks/useGetHookEffect";
import useUI from "../usefulHooks/useUI";
import useChat from "../usefulHooks/useChat";

export default function useBlockedContacts() {
  const { chatOption, setChatOption } = useChat();
  const { setInterface } = useUI();
  const isAlreadyFetched = !chatOption.blockedContacts.isFetched;
  const {response} = useGetHookEffect(
    "/api/account/settings/blocked-contacts",
    isAlreadyFetched,
    false
  );

  useEffect(() => {
    if (response) {
      if (response.status === 200) {
        setChatOption((rest) => ({
          ...rest,
          blockedContacts: { isFetched: true, blocked: response?.blocked_contacts },
        }));
        setInterface({ alert: null });
      } else {
        setInterface({ alert: { message: response.message, success: false } });
      }
    }
  }, [response]);

  return;
}
