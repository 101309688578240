import axios from "axios";
const base_Url = "https://www.strrings.com";

export default axios.create({
  baseURL: base_Url,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export const privateAxios = axios.create({
  baseURL: base_Url,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
