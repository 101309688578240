import useSocket from "./useSocket";
import useAuth from "../usefulHooks/useAuth";
import useChat from "../usefulHooks/useChat";
import useUI from "../usefulHooks/useUI";

export default function useSocketEventHandlers(userID) {
  const { socket } = useSocket();
  const { chatOption, setChatOption } = useChat();
  const { setInterface } = useUI();
  const {
    auth: { user },
  } = useAuth();

  const TYPING = () => {
    socket.emit("typing", userID);
  };

  const NOT_TYPING = () => {
    socket.emit("not-typing", userID);
  };

  const SEND_MESSAGE = (input) => {
    const data = {
      sender: user.userID,
      receiver: userID,
      message: {
        content: input,
        contentType: "text",
        sent: false,
        delivered: false,
        read: false,
        timestamp: new Date(),
      }, // we will change content type when we will send different type of media
    };

    if (input.trim() !== "") {
      socket.emit("send", data);
      let otherUserMessages = chatOption.messages;
      let userMessages = otherUserMessages[data.receiver] || []
      setChatOption((rest) => ({
        ...rest,
        messages: {
          ...otherUserMessages,
          [data.receiver]: [...userMessages, data],
        },
      }));
    }

    return;
  };

  const CHAT_OPENED = () => {
    if (socket) {
      socket.emit("opened-chat", {
        receiver: userID,
      }); // if the receiver chat open
    }
  };

  const CHAT_CLOSED = () => {
    if (socket) {
      socket.emit("closed-chat", {
        receiver: userID,
      });
    }
  };

  const MARK_AS_READ = () => {
    if (socket) {
      let unreadMsg = chatOption.unreadMessage?.[userID] || [];

      if (unreadMsg.length) {
        socket.emit("mark-as-read", {
          receiver: userID,
          unreadMessages: unreadMsg,
        });
      }
    }
  };

  const DELETE_MSG = (messageToDelete) => {
    if (socket) {
      socket.emit("delete-message", { contactID: userID, messageToDelete });
    }
  };
  const DELETE_ALL_MSG = (userID) => {
    if (socket) {
      if (chatOption.messages[userID].length) {
        socket.emit("delete-all-message", { contactID: userID });
      } else {
        setInterface({
          alert: {
            success: true,
            message: "Chat already cleared",
          },
        });
      }
    }
  };

  const ADD_TO_CHATS = (contactID) => {
    if (socket) {
      socket.emit("add-to-chats", contactID);
    }
  };

  const REMOVE_FROM_CHATS = (contactID) => {
    if (socket) {
      socket.emit("remove-from-chats", contactID);
    }
  };

  const BLOCK_USER = (contactID) => {
    if (socket) {
      socket.emit("block-user", contactID);
    }
  };

  const UNBLOCK_USER = (contactID) => {
    if (socket) {
      socket.emit("unblock-user", contactID);
    }
  };

  return { 
    SEND_MESSAGE,
    TYPING,
    NOT_TYPING,
    CHAT_OPENED,
    CHAT_CLOSED,
    MARK_AS_READ,
    DELETE_MSG,
    DELETE_ALL_MSG,
    UNBLOCK_USER,
    BLOCK_USER,
    ADD_TO_CHATS,
    REMOVE_FROM_CHATS,
  };
}
