import axios from "../../api/axios";
import useAuth from "./useAuth";

export const useRefreshToken = () => {
  const { setAuth } = useAuth();

  /* whenever our initial request fail or our accessToken is 
    expired this funciton is called by axiosPrivate to get new accessToken*/
  let refresh = async () => {
    let response = await axios.get("/api/refresh", { withCredentials: true });

    setAuth((rest) => ({
      ...rest,
      accessToken: response.data?.accessToken,
      socketToken: response.data?.socketToken,
      user: response.data?.userWithToken,
    }));
    return response.data?.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
