import { ReactComponent as Error404 } from "../../../svg/error404.svg";
import { ReactComponent as NoContact } from "../../../svg/noContact.svg";
import { ReactComponent as Error } from "../../../svg/error.svg";

export default function NotAvailable({ message, convey }) {
  let illustration = {
    noContacts: <NoContact />,
    notFound: <Error404 />,
    error: <Error />,
  };

  return (
    <div id="notAvailable" className="smooth-anim">
      {illustration[convey]}
      <p className="errorInfo">{message}</p>
    </div>
  );
}
