import { createContext, useState } from "react";
const ChatContext = createContext({});

export function ChatProvider({ children }) {
  let [chatOption, setChatOption] = useState({
    isSaved: true,
    isChatOpen: false,
    isUserTyping: [],
    onlineUsers: [],
    messages: {}, // It will be used to store fetched messsage from api
    newMessages:
      {} /* It will be used to store temporary messages that we received from sender,
       these message will be moved in 'messages' object after fetched messages*/,
    contacts: { isFetched: false, allContacts: [] },
    blockedContacts: { isFetched: false, blocked: [] },
    unreadMessage: {},
    lastMessage: {},
    isRecentMessagesFetched: false,
    error: null,
    currentUser: null,
  });

  return (
    <ChatContext.Provider value={{ chatOption, setChatOption }}>
      {children}
    </ChatContext.Provider>
  );
}

export default ChatContext;
