import { useEffect, useState } from "react";
import useGetHookEffect from "../httpHooks/useGetHookEffect";
import useChat from "../usefulHooks/useChat";

export default function useContacts() {
  const [item, setItem] = useState({
    error: null,
    isLoaded: true,
  });
  const { chatOption, setChatOption } = useChat();
  const isAlreadyFetched = !chatOption.contacts.isFetched;
  const {response} = useGetHookEffect("/api/user-chats", isAlreadyFetched, false);

  useEffect(() => {
      setItem((rest) => ({ ...rest,isLoaded: chatOption.contacts.isFetched }));
    if (response) {
      if (response?.status === 200) {
        setChatOption((rest) => ({
          ...rest,
          contacts: { isFetched: true, allContacts: response?.contacts },
        }));
        setItem((rest) => ({ ...rest, error: null, isLoaded: true }));
      } else {
        setItem((rest) => ({ ...rest, error: response, isLoaded: true }));
      }
    }
  }, [response]);

  return item;
}
