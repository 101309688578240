export default function handleResponse(res, success) {
  if (!success) {
    let getStatus = res?.request?.status || 500;
    if (res.hasOwnProperty("response")) {
      let getData = res?.response?.data || "Something went wrong!";
      if (typeof getData === "string") {
        return {
          message: getData,
          success: false,
          status: res?.response?.status || 500,
        };
      } else {
        getData.status = res?.response?.status; 
        return getData;
      }
    }
    return {
      message: res?.message,
      success: false,
      status: getStatus,
    };
  } else {
    if (res.hasOwnProperty("data")) {
      return { ...res.data, status: res?.status };
    } else {
      return res.response;
    }
  }
}
