import handleResponse from "../../modules/responseHandler";
import useAxiosPrivate from "../usefulHooks/useAxiosPrivate";
import useUI from "../usefulHooks/useUI";

export default function usePutHook() {
  const axiosPrivate = useAxiosPrivate();
  const { setInterface } = useUI();

  async function PUT(info, url, content, showProgress = false) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const timeoutId = setTimeout(() => {
      abortController.abort();
    }, 60000);

    let data = content.includes("multipart/form-data")
      ? info
      : JSON.stringify(info);

    let option = {
      headers: {
        Accept: "application/json",
        "Content-Type": content,
      },
      signal,
    };

    setInterface({ isLoaded: !showProgress });

    try {
      const sendData = await axiosPrivate.put(url, data, option);
      return handleResponse(sendData, true);
    } catch (err) {
      const error = handleResponse(err, false);
      if (error.message === "canceled") {
        error.message = "Request canceled due to delay. Please retry.";
      }

      return error;
    } finally { 
      clearTimeout(timeoutId); // Clear the timeout if the request succeeds
      abortController.abort(); // Abort the request
    }
  }
  return PUT;
}
