import useAxiosPrivate from "../usefulHooks/useAxiosPrivate";
import handleResponse from "../../modules/responseHandler";
import useUI from "../usefulHooks/useUI";

export default function usePostHook() {
  const axiosPrivate = useAxiosPrivate();
  const { setInterface } = useUI();

  async function POST(info, url, showProgress = false) {
    const data = JSON.stringify(info);
    const abortController = new AbortController();
    const signal = abortController.signal;
    const timeoutId = setTimeout(() => {
      abortController.abort();
    }, 60000);

    setInterface({ isLoaded: !showProgress });
    try {
      const sendData = await axiosPrivate.post(url, data, { signal });
      return handleResponse(sendData, true);
    } catch (err) {
      const error = handleResponse(err, false);
      if (error.message === "canceled") {
        error.message = "Request canceled due to delay. Please retry.";
      }

      return error;
    } finally { 
      clearTimeout(timeoutId);
      abortController.abort(); // Abort the request
    }
  }
  return POST;
}
