import React from "react";
import { useNavigate } from "react-router-dom";

export default function ManagerHeader({ setSection, item }) {
  const navigate = useNavigate();

  function goBack() {
    return navigate(-1);
  }

  let sections = [
    ["Personal Information", "fas fa-user-circle"],
    ["Danger-Zone", "fas fa-trash-alt"],
  ];

  return (
    <div id="manager-header">
      <div id="backBtn_and_heading">
        <button className="backButton back background" onClick={goBack}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h4>My Account</h4>
      </div>

      <ul className="manager-dropdown-container">
        {sections.map((e, i) => (
          <li key={i}>
            <button
              className={`prfile-btn background sec${i} ${
                e[0] === item.current_section ? "selected" : ""
              }`}
              onClick={() => setSection(e[0])}
              type="button"
            >
              <i className={e[1]}></i>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
