import { useState } from "react";
import useRecover from "../../../hooks/componentHooks/recoverHook";
import "./recover.scss";
import ERROR from "../../usefulComponents/informativeComponents/ERROR";
import Ellipsis from "../../usefulComponents/loadingComponents/ellipsis";
import EmailSuccess from "./emailSuccess";
import AnimBackground from "../../usefulComponents/otherComponents/svgBackground";

export default function Recover() {
  const SUBMIT_INFO = useRecover();
  let [getValue, setValue] = useState({
    email: "",
    error: null,
    isLoaded: true,
    success: false,
  });

  async function submitData(e) {
    e.preventDefault();
    setValue((rest) => ({ ...rest, error: null, isLoaded: false }));
    const { message, status } = await SUBMIT_INFO("/user/password/recovery", {
      email: getValue.email,
    });

    status !== 200
      ? setValue((rest) => ({
          ...rest,
          isLoaded: true,
          success: false,
          error: { message, status },
        }))
      : setValue((rest) => ({
          ...rest,
          error: null,
          success: true,
          isLoaded: true,
        }));
  }

  function handleChange(e) {
    const { value, name } = e.target;
    setValue((rest) => ({ ...rest, [name]: value, error: null }));
  }

  return (
    <div className="recover-container">
      {!getValue.success ? (
        <div className="reset glass">
          <div className="resetPassword">
            <img
              src="https://d34ct18kvr7jx3.cloudfront.net/assets/logo/logo96.png"
              alt="logo"
            />
            <h1>Reset password</h1>
            <p>Enter your registered email below to reset your password.</p>
          </div>

          <form onSubmit={submitData}>
            <div>
              <input
                type="email"
                name="email"
                value={getValue.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <ERROR error={getValue.error} />
            </div>

            <div id="submitEmail">
              {!getValue.isLoaded ? (
                <Ellipsis />
              ) : (
                <input type="submit" value="Submit" className="myBtn" />
              )}
            </div>
          </form>
        </div>
      ) : (
        <EmailSuccess email={getValue.email} />
      )}
      <AnimBackground
        color0={"#322DED"}
        color1={"#B537F0"}
        color2={"#F241EF"}
        color3={"#3C37F0"}
        color4={"#F2669B"}
      />
    </div>
  );
}
