import React, { useRef, useEffect } from "react";
import "./currentChat.scss";
import shortTime from "../../../../modules/shortTime";
import MessageStatus from "../../../usefulComponents/chatComponents/messageStatus";
import emitSocketEvent from "../../../../hooks/socketHooks/socketEmitEvents";
import useChat from "../../../../hooks/usefulHooks/useChat";
import isValueExist from "../../../../modules/isValueExist";

export default function Messages({
  userID,
  selected,
  setSelected,
  selectionMode,
  setSelectionMode,
}) {
  const scrollRef = useRef(null);
  const { ADD_TO_CHATS, UNBLOCK_USER, BLOCK_USER } = emitSocketEvent();
  const { chatOption } = useChat();
  const userMessages = chatOption.messages?.[userID] || [];

  useEffect(() => {
    const element = scrollRef.current;
    element.scrollTo(0, element.scrollHeight);
    /* To scroll to bottom (recent message) when user send message and open a chat of any user*/
  }, [chatOption.messages,userID]);

  function handleSelcted(messageId, message, i) {
    if (selectionMode) {
      const element = document.getElementById(`key-${i}`);
      let alreadySelected = false;
      if (selected && selected.hasOwnProperty(messageId)) {
        alreadySelected = true;
        let unSelect = selected;
        delete selected[messageId];
        if (!Object.keys(selected).length) {
          setSelected(null);
        } else {
          setSelected(unSelect);
        }
        element.classList.remove("selected");
      }
      if (!alreadySelected) {
        setSelected((msg) => ({ ...msg, [messageId]: message }));
        element.classList.add("selected");
      }
    }
  }

  function handleMenu(e) {
    e.preventDefault();
    setSelectionMode(true);
  }

  function getTimings(prev, next) {
    let validPrev;
    if (!prev) {
      let validDate = new Date(next?.message?.timestamp);
      let previousDate = new Date(validDate);
      previousDate.setDate(validDate.getDate() - 1);
      validPrev = previousDate;
    }

    let previousDay = new Date(prev?.message?.timestamp || validPrev);
    let nextDay = new Date(next?.message?.timestamp);

    if (previousDay.getDate() < nextDay.getDate()) {
      return nextDay.toDateString();
    } else {
      return "";
    }
  }

  let blockOrUnblock = ["Block", BLOCK_USER];

  if (isValueExist(chatOption.blockedContacts.blocked, "userID", userID)) {
    blockOrUnblock = ["Unblock", UNBLOCK_USER];
  }

  return (
    <div ref={scrollRef} className="messages-container">
      {!chatOption.isSaved ? (
        <div className="unknown-contact">
          <p>This user is not saved in your chat contacts.</p>
          <div className="unknown-contact-action">
            <button
              onClick={() => blockOrUnblock[1](userID)}
              id="blockNow"
              className="myBtn"
            >
              {blockOrUnblock[0]}
            </button>
            <button
              onClick={() => ADD_TO_CHATS(userID)}
              id="addNow"
              className="myBtn"
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {userMessages.map((e, i) => [
        getTimings(userMessages[i - 1], userMessages[i]) ? (
          <div key={i} className="message-date">
            <span>{new Date(e?.message?.timestamp).toDateString()}</span>
          </div>
        ) : (
          ""
        ),

        e?.sender !== userID ? (
          <div
            onClick={() =>
              handleSelcted(e?.message?.messageID, e?.message?.content, i)
            }
            onContextMenu={handleMenu}
            key={i + e?.message?.messageID}
            id={`key-${i}`}
            className="right background"
          >
            <div className="message">
              <div>
                <p>{e?.message?.content}</p>
                <div className="msg_info">
                  <span className="msg_time">
                    {shortTime(e?.message?.timestamp)}
                  </span>
                  <MessageStatus e={e} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() =>
              handleSelcted(e?.message?.messageID, e?.message?.content, i)
            }
            onContextMenu={handleMenu}
            key={i + e?.message?.messageID}
            id={`key-${i}`}
            className="left background"
          >
            <div className="message">
              <div>
                <p>{e?.message?.content}</p>
                <div className="msg_info">
                  <span className="msg_time">
                    {shortTime(e?.message?.timestamp)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
      ])}
    </div>
  );
}
