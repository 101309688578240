import React from "react";
import useUI from "../../../../../hooks/usefulHooks/useUI";
import ContactList from "./contactList";
import Profile from "../../profile/profile";

export default function SearchLayout() {
  const { Interface } = useUI();
  const { searchResult, searchMode, show_Profile } = Interface;

  let setLayout = searchMode ? "searchLayout unfold-anim" : "hide";
  let users = searchResult.isSearched && !searchResult.users.length;
  let foundUsers =
    searchResult.isSearched && searchResult.users.length
      ? `${searchResult.users.length} user(s) found :`
      : "All Users";

  let info = null;
  if (searchResult.error) {
    if (searchResult.error.status !== 404) {
      info = ["error", searchResult.error?.message];
    } else {
      info = ["notFound", searchResult.error?.message];
    }
  }

  return (
    <div className={setLayout}>
      {!users ? (
        <div className="search-result">
          <h2>{foundUsers}</h2>
          <hr />
        </div>
      ) : (
        ""
      )}
      {[
        show_Profile ? <Profile key={1} /> : "",
        <ContactList key={2} contacts={searchResult.users} error={info} />,
      ]}
    </div>
  );
}
