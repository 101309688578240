import "../App.scss";
import HOME from "./protectedComponents/Home/home";
import Signin from "./auth/authentication/signin";
import Signup from "./auth/authentication/signup";
import ManageProfile from "./protectedComponents/Manager/ManageProfile";
import Error from "./error/error";
import { Routes, Route } from "react-router-dom";
import Layout from "./auth/outlets/Layout";
import RequireAuth from "./auth/outlets/requireAuth";
import PersistLogin from "./auth/outlets/PersistLogin";
import UserVerification from "./auth/outlets/UserVerification";
import Recover from "./auth/recovery/recover";
import SetPassword from "./protectedComponents/password/SetPassword";
import Chat from "./protectedComponents/Chat/chat";
import useUI from "../hooks/usefulHooks/useUI";
import Settings from "./protectedComponents/settings/settings";

function App() {
  let { setInterface } = useUI();
  function clickHandler(e) {
    e.stopPropagation();
    return setInterface({ show_Options: false });
  }

  return (
    <div onClick={(e) => clickHandler(e)}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="signin" element={<Signin />} />
          <Route path="signup" element={<Signup />} />
          <Route path="password/recovery" element={<Recover />} />

          <Route element={<UserVerification />}>
            <Route
              path="user/passwordrecovery/setnewpassword/:token"
              element={<SetPassword />}
            />
          </Route>

          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<HOME />} />
              <Route path="settings" element={<Settings />} />
              <Route path="manage-user-profile" element={<ManageProfile />} />
              <Route path="chats/:userID" element={<Chat />} />
            </Route>
          </Route>

          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
