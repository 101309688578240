import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useUI from "../../../../hooks/usefulHooks/useUI";
import { insertNewlineAtCursor } from "../../../../modules/newLine";
import Profile from "../../Home/profile/profile";
import emitSocketEvent from "../../../../hooks/socketHooks/socketEmitEvents";
import useOnline from "../../../../hooks/utilityHooks/useOnline";
import useGetUser from "../../../../hooks/componentHooks/getUser";
import useGetMessages from "../../../../hooks/componentHooks/getMessages";
import useBlockedContacts from "../../../../hooks/componentHooks/getBlockedContacts";
import useSocket from "../../../../hooks/socketHooks/useSocket";
import Messages from "./messages";
import Dropdown from "./dropdown";
import useChat from "../../../../hooks/usefulHooks/useChat";
import isValueExist from "../../../../modules/isValueExist";
import shortName from "../../../../modules/shortName";

export default function CurrentChat() {
  const { socket } = useSocket();
  const textareaRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { setInterface, Interface } = useUI();
  const { chatOption, setChatOption } = useChat();
  const { isActive } = useOnline();
  const { userID } = params;
  const { userToChat } = useGetUser(userID); // if user refresh page so that it make get request to get user
  const person = chatOption?.currentUser || userToChat;
  useGetMessages(userID);
  useBlockedContacts();

  const {
    SEND_MESSAGE,
    TYPING,
    NOT_TYPING,
    CHAT_OPENED,
    CHAT_CLOSED,
    MARK_AS_READ,
    DELETE_MSG,
  } = emitSocketEvent(userID);

  const [input, setInput] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [selected, setSelected] = useState(null);

  function showFriendProfile() {
    setInterface({
      profile: person,
      show_Profile: !Interface.show_Profile,
    });
    return;
  }

  function closeChat() {
    setInterface({ show_Profile: false });
    navigate(-1);
    return;
  }

  function sendAndClear(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleBlur();
      e.preventDefault();
      SEND_MESSAGE(input);
      setInput("");
    } else if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      insertNewlineAtCursor(textareaRef);
      let element = textareaRef.current;
      element.style.height = element.style.height + 10;
    }
  }

  function send(e) {
    e.preventDefault();
    SEND_MESSAGE(input);
    setInput("");
  }

  function handleChange(e) {
    setInput(e.target.value);
    handleFocus();
  }

  function handleFocus() {
    let element = textareaRef.current;
    let hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
    /*To automatically increase the height of textarea when scroll bar appears on textarea*/
    if (element.rows < 10 && hasVerticalScrollbar) {
      element.rows += 2;
    }
    TYPING();
  }

  function handleBlur() {
    handleTextAreaHeight();
    NOT_TYPING();
  }

  function handleTextAreaHeight() {
    let element = textareaRef.current;
    let hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
    if (element.rows < 10 && hasVerticalScrollbar) {
      element.rows += 2;
    } else if (input.length < 15 || !input.length) {
      element.rows = 1;
    }
  }

  useEffect(() => {
    // To close chat when window lose its focus or use move to other app/tab
    const handleBlur = () => {
      CHAT_CLOSED();
      setInterface({ show_Profile: false });
      setChatOption((rest) => ({ ...rest, isChatOpen: false }));
      navigate(-1);
    };

    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    setChatOption((rest) => ({ ...rest, isChatOpen: true }));
    CHAT_OPENED();
    MARK_AS_READ(); 

    return () => {
      CHAT_CLOSED();
      setChatOption((rest) => ({ ...rest, isChatOpen: false }));
      setInterface({ show_Profile: false });
    };

    /*I am included second dependency 
    (chatOption.unreadMessage) to get the latest status
     of message when use directly open chat. For example
      when user open the chat directly with url (http://localhost:3000/chats/0b5330f1-bb21-4b71-93b3-248adf925515) */
  }, [socket, chatOption.unreadMessage, userID]);

  function disableSelectionMode() {
    let elements = [...document.getElementsByClassName(`selected`)];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      element.classList.remove("selected");
    }
    setSelected(null);
    setSelectionMode(false);
  }

  const handleOutsideClick = (e) => {
    e.stopPropagation();
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  function deleteSelectedMsg() {
    if (selected) {
      let msgIds = Object.keys(selected);
      DELETE_MSG(msgIds);
      disableSelectionMode();
    }
  }

  function copySelectedMsg() {
    let textToCopy = "";

    for (let i in selected) {
      textToCopy += selected[i] + "\n";
    }
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        disableSelectionMode();
        setInterface({
          alert: {
            success: true,
            message: "Text has been copied to the clipboard!",
          },
        });
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard: ", error);
      });
  }

  let chatInterface =
    Interface.collapse || Interface.mobile_view ? "wider" : "shrink";
  let online = person ? isActive(person) : "";

  let isBlocked = isValueExist(
    chatOption.blockedContacts.blocked,
    "userID",
    userID
  );

  return (
    <div
      onClick={handleOutsideClick}
      className={`chat-container ${chatInterface}`}
    >
      {Interface.show_Profile ? <Profile /> : ""}
      <div className="chat-header">
        <div className="chat-header-Left">
          <div className="back background">
            <button className="backButton" onClick={closeChat}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
          </div>

          <div className="checkProfile background" onClick={showFriendProfile}>
            <div id="currentChatDp-icon">
              <img
                className="currentChatDp"
                src={person?.picture}
                alt="profile_img"
              />
            </div>

            <div className="chatWith">
              <p>{shortName(person?.username, 15)}</p>
              <span className="online_status">{online}</span>
            </div>
          </div>
        </div>

        <div className="chat_actions">
          {selectionMode && (
            <div className="single_option">
              <button
                title="Delete"
                className="option-bar chat-opt background"
                onClick={deleteSelectedMsg}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
              <button
                title="Copy"
                className="option-bar chat-opt background"
                onClick={copySelectedMsg}
              >
                <i className="far fa-copy"></i>
              </button>
              <button
                title="Disable selection mode"
                onClick={disableSelectionMode}
                className="option-bar chat-opt background"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          )}

          <Dropdown
            userID={userID}
            menuVisible={menuVisible}
            setMenuVisible={setMenuVisible}
            selectionMode={selectionMode}
          />
        </div>
      </div>
      <Messages
        userID={userID}
        selected={selected}
        selectionMode={selectionMode}
        setSelected={setSelected}
        setSelectionMode={setSelectionMode}
      />
      {isBlocked ? (
        <div className="smooth-anim" id="its_blocked">
          <p>
            You are not able to send a message to this user because it is
            blocked by you.
          </p>
        </div>
      ) : (
        <form
          onSubmit={sendAndClear}
          className="smooth-anim"
          id={`message-inputs`}
        >
          <textarea
            id="type_message"
            onChange={handleChange}
            onKeyDown={sendAndClear}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={textareaRef}
            placeholder="Type a message"
            value={input}
            rows="1"
          ></textarea>

          <input
            type="image"
            className="send background"
            src="https://d34ct18kvr7jx3.cloudfront.net/assets/icons/send3.png"
            alt="send_btn"
            onClick={send}
          />
        </form>
      )}
    </div>
  );
}
