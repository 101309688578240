export default function getLastSeenMessage(time) {
  const currentTime = new Date();
  const lastSeenTime = new Date(time);

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime.getDate() - lastSeenTime.getDate();

  if (timeDifference === 0) {
    // Last seen today
    const formattedTime = lastSeenTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    return `Last seen today at ${formattedTime}`;
  } else if (timeDifference === 1) {
    // Last seen yesterday
    const formattedTime = lastSeenTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    return `Last seen yesterday at ${formattedTime}`;
  } else {
    // Last seen on a previous date
    const formattedDate = lastSeenTime.toLocaleDateString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
    return `Last seen on ${formattedDate}`;
  }
}
