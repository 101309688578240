import emitSocketEvent from "../../../../hooks/socketHooks/socketEmitEvents";
import useChat from "../../../../hooks/usefulHooks/useChat";
import isValueExist from "../../../../modules/isValueExist";

export default function Dropdown({
  userID,
  menuVisible,
  setMenuVisible,
  selectionMode,
}) {
  const { chatOption } = useChat();
  const { DELETE_ALL_MSG, BLOCK_USER, UNBLOCK_USER, REMOVE_FROM_CHATS } =
    emitSocketEvent();

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  let dropdown = menuVisible ? "dropdown_active" : "";
  let threeDots = selectionMode ? "hide" : "";
  let blockOrUnblock = ["Block", BLOCK_USER];

  if (isValueExist(chatOption.blockedContacts.blocked, "userID", userID)) {
    blockOrUnblock = ["Unblock", UNBLOCK_USER];
  }

  return (
    <div className={`dropdown_div ${threeDots}`}>
      <button
        className={`dropdown_button option-bar background ${dropdown}`}
        onClick={toggleMenu}
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>
      {menuVisible && (
        <ul className="dropdown_menu fadeInOut-anim blur_bg">
          <li
            className="background dropdown-items"
            onClick={() => blockOrUnblock[1](userID)}
          >
            <i className="fas fa-ban"></i> {blockOrUnblock[0]}
          </li>

          {chatOption.isSaved || blockOrUnblock[0] === "block" ? (
            <li
              className="background dropdown-items"
              onClick={() => REMOVE_FROM_CHATS(userID)}
            >
              <i className="fas fa-user-minus"></i> Remove from chats
            </li>
          ) : (
            ""
          )}

          <li
            className="background dropdown-items"
            onClick={() => DELETE_ALL_MSG(userID)}
          >
            <i className="fas fa-broom"></i> Clear chat
          </li>
          <li className="app-tip">
            <p>Tip : Long press on message to delete and copy message(s)</p>
          </li>
        </ul>
      )}
    </div>
  );
}
