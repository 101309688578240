import { useEffect, useState } from "react";
import useUI from "../../../../hooks/usefulHooks/useUI";
import shortName from "../../../../modules/shortName";
import Spinner from "../../../usefulComponents/loadingComponents/spinner";
import useChat from "../../../../hooks/usefulHooks/useChat";
import emitSocketEvent from "../../../../hooks/socketHooks/socketEmitEvents";

export default function BlockedContacts() {
  const [contactID, setContactID] = useState("");
  const { UNBLOCK_USER } = emitSocketEvent();
  const { Interface } = useUI();

  useEffect(() => {
    if (contactID !== "") {
      UNBLOCK_USER(contactID);
    }
    return () => setContactID("");
  }, [contactID]);

  const {
    chatOption: {
      blockedContacts: { blocked },
    },
  } = useChat();

  return (
    <div id="blocked_contacts">
      {Interface.isLoaded ? (
        <ul className="contents-container">
          {!blocked.length ? (
            <div id="no-blocked-contacts">
              <h3>No blocked contacts</h3>
              <p>
                Blocked contacts will no longer be able to send you messages.
              </p>
            </div>
          ) : (
            blocked.map((user, i) => {
              return (
                <li className={`contactsList`} key={i}>
                  <div className="avatarList">
                    <img
                      className="contactsAvatar"
                      src={user?.picture}
                      alt="profile_img"
                    />
                  </div>
                  <div className="nameList">
                    <div className="contact">
                      <div className="contact_name">
                        <span>{shortName(user.username)}</span>
                        <button
                          onClick={() => setContactID(user.userID)}
                          className="unblock_btn myBtn"
                        >
                          Unblock
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          )}
        </ul>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
