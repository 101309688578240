import { useEffect, useState, useRef } from "react";
import "./sixInput.scss";

export default function SixInput({
  get_otp,
  // handleSubmit,
  clearInput,
  setItem,
}) {
  const otpForm = useRef(null);
  const [input, setInput] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  useEffect(() => {
    let inputs = document.getElementsByClassName("otp");
    let index = 0;

    otpForm.current.onkeyup = function (e) {
      // if (handleSubmit && e.keyCode === 13) {
      //   handleSubmit(e);
      // } else
      if (e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39) {
        if (inputs[index].value !== "" && index < inputs.length - 1) {
          index++;
          inputs[index].focus();
        }
      } else if (e.keyCode === 37 && index > 0) {
        index--;
        inputs[index].focus();
      } else if (e.keyCode === 39 && index < inputs.length - 1) {
        index++;
        inputs[index].focus();
      } else if (e.keyCode === 8 && e.keyCode !== 37 && e.keyCode !== 39) {
        if (inputs[index].value === "" && index > 0) {
          index--;
          inputs[index].focus();
        }
      }
    };
    return () => {
      setInput((rest) => ({
        ...rest,
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
      }));
    };
  }, []);

  useEffect(() => {
    if (clearInput) {
      setInput((rest) => ({
        ...rest,
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
      }));
    }
    return () => setItem((rest) => ({ ...rest, clearInput: false }));
  }, [clearInput]);

  function handleChange(e) {
    const { value, name } = e.target;
    if (/^[0-9]*$/.test(value)) {
      setInput((rest) => ({ ...rest, [name]: value }));
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let code = "";
      for (let key in input) {
        code += input[key];
      }
      get_otp(code);
    }
    return () => (isMounted = false);
  }, [input]);

  return (
    <>
      <div id="otp_container">
        <div id="enter_code_p">
          <span>Enter the code here:</span>
        </div>
        <div id="otpForm" ref={otpForm}>
          <input
            onChange={handleChange}
            maxLength="1"
            className="otp"
            type="input"
            name="otp1"
            value={input.otp1}
            // required
          />
          <input
            onChange={handleChange}
            maxLength="1"
            className="otp"
            type="input"
            name="otp2"
            value={input.otp2}
            // required
          />
          <input
            onChange={handleChange}
            maxLength="1"
            className="otp"
            type="input"
            name="otp3"
            value={input.otp3}
            // required
          />
          <input
            onChange={handleChange}
            maxLength="1"
            className="otp"
            type="input"
            name="otp4"
            value={input.otp4}
            // required
          />
          <input
            onChange={handleChange}
            maxLength="1"
            className="otp"
            type="input"
            name="otp5"
            value={input.otp5}
            // required
          />
          <input
            onChange={handleChange}
            maxLength="1"
            className="otp"
            type="input"
            name="otp6"
            value={input.otp6}
            // required
          />
        </div>
      </div>
    </>
  );
}
