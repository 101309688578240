import React, { useEffect } from "react";
import useUI from "../../../hooks/usefulHooks/useUI";
import { ReactComponent as Success } from "../../../svg/success.svg";
import { ReactComponent as Failed } from "../../../svg/failed.svg";

export default function Alert() {
  let { setInterface, Interface } = useUI();
  let { alert } = Interface;

  let setType = alert?.success
    ? ["green_alert", <Success />, alert?.message]
    : ["red_alert", <Failed />, alert?.message];

  useEffect(() => {
    let timeout = setTimeout(() => {
      setInterface({ alert: null });
    }, 2500);
    return () => clearTimeout(timeout);
  }, [alert]);

  return (
    <div
      id="Alert"
      className={`${!alert ? "hide" : ""} components_background ${setType[0]}`}
    >
      {setType[1]}
      <span>{setType[2]}</span>
    </div>
  );
}
