import React from "react";
import "./offline_svg.scss";

export default function OfflineSvg() {
  return (
    <svg
      viewBox="-6.4 -6.4 76.80 76.80"
      id="offline-svg"  
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <rect
          x="-6.4"
          y="-6.4" 
          rx="38.4"
        />
      </g>

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="0.512"
      />

      <g id="SVGRepo_iconCarrier">
        <defs></defs>

        <title />

        <path
          className="cls-1"
          d="M43.62,35H37.16a2,2,0,0,1,0-4h1.63l-14-14a2,2,0,0,1,2.82-2.83L45,31.56A2,2,0,0,1,43.62,35Z"
        />

        <path
          className="cls-1"
          d="M43.62,35a2,2,0,0,1-2-2V26.3a2,2,0,0,1,4,0V33A2,2,0,0,1,43.62,35Z"
        />

        <path
          className="cls-1"
          d="M37.84,50.49a2,2,0,0,1-1.41-.59L19,32.44A2,2,0,0,1,20.38,29h6.46a2,2,0,1,1,0,4H25.21l14,14a2,2,0,0,1-1.41,3.42Z"
        />

        <path
          className="cls-1"
          d="M20.38,39.7a2,2,0,0,1-2-2V31a2,2,0,0,1,4,0V37.7A2,2,0,0,1,20.38,39.7Z"
        />

        <path
          className="cls-2"
          d="M32,60.21A28.21,28.21,0,1,1,60.21,32a2,2,0,0,1-4,0,24.2,24.2,0,1,0-1.83,9.24,2,2,0,0,1,3.69,1.53A28.12,28.12,0,0,1,32,60.21Z"
        />
      </g>
    </svg>
  );
}
