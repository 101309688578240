
export default async function getNotificationPermission(isEnabled) {
  let getPermission = "granted";
  if (!isEnabled) {
    getPermission = await Notification.requestPermission();
  }

  if (getPermission === "denied" || getPermission === "default") {
    return false;
  } else {
    return true;
  }
}