

export const insertNewlineAtCursor = (elementRef) => {
  const textarea = elementRef.current;
  const start = textarea.selectionStart;
  const end = textarea.selectionEnd;
  const value = textarea.value;

  // Insert a newline character at the current cursor position
  const newValue = value.substring(0, start) + "\n" + value.substring(end);

  // Update the textarea value and move the cursor to the correct position
  textarea.value = newValue;
  textarea.setSelectionRange(start + 1, start + 1);
};

