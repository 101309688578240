import React from "react";
import { useState } from "react";
import usePost from "../../../../hooks/httpHooks/usePostHook";
import useUI from "../../../../hooks/usefulHooks/useUI";

export default function Feedback() {
  const [text, setText] = useState("");
  const { Interface, setInterface } = useUI();
  const POST = usePost();

  function handleText(e) {
    setText(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { success, message, status } = await POST(
      { message: text },
      "/api/user/feedback",
      true
    );
    if (status === 200) {
      setInterface({
        isLoaded: true,
        alert: { success, message },
      });
    } else {
      setInterface({
        alert: { success: false, message },
        isLoaded: true,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit} id="help_form">
      <textarea
        onChange={handleText}
        value={text}
        name="help"
        id="helpText"
        placeholder="Type your message here..."
        cols="30"
        rows="15"
        required
      ></textarea>
      {Interface.isLoaded ? (
        <input className="myBtn" type="submit" value="Submit" />
      ) : (
        ""
      )}
    </form>
  );
}
